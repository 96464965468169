import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Client from '../../interfaces/client'

interface ClientState {
    clients: Client[],
}

const initialState: ClientState = {
    clients: []
}

export const clientSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        loadClients: (state, action: PayloadAction<Client[]>) => {
            state.clients = action.payload
        }
    }
});

export const {loadClients} = clientSlice.actions;

export default clientSlice.reducer;