import { GridColDef } from "@mui/x-data-grid";

const fieldColumnDef: GridColDef[] = [
    { field: 'field_id', headerName: 'ID', width: 90 },
    { field: 'field_name', headerName: 'Field Name', width: 150, sortable: true, filterable: true },
    { field: 'is_standard', headerName: 'Standard', width: 150, sortable: true, filterable: true },
    { field: 'field_description', headerName: 'Field Description', width: 150, sortable: true, filterable: true },
    { field: 'field_value_type', headerName: 'Value Type', width: 150, sortable: true, filterable: true },
    { field: 'field_type', headerName: 'Field Type', width: 150, sortable: true, filterable: true },
    { field: 'field_reference', headerName: 'Field Reference', width: 150, sortable: true, filterable: true },
    { field: 'created_date', headerName: 'Created Date', width: 150, sortable: true, filterable: true },
    { field: 'created_by', headerName: 'Created By', width: 100, sortable: true, filterable: true },
]

export default fieldColumnDef;