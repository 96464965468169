import { Box, Button, IconButton, Modal, TextField, Typography } from "@mui/material";
import modalStyle from "../../styles/modalStyle";
import apiClient from "../../axiosClient";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import SearchComponent from "../baseComponents/searchComponent";
import InternalUser from "../../interfaces/internal_user";
import DropDown from "../baseComponents/dropdown";
import Territory from "../../interfaces/territory";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import filterSectionStyle from "../../styles/filterSectionStyle";

interface ModalProps {
    openModal: boolean,
    handleCloseModal: () => void,
}

const AddAgentsToTerritoryModal: React.FC<ModalProps> = ({ openModal, handleCloseModal }) => {
    const [newAgent, setNewAgent] = useState<{ id: string; name: string }[]>([]);
    const [selectedTerritories, setSelectedTerritories] = useState<string[]>([]);

    const territories: Territory[] = useSelector((state: RootState) => state.territories.territories);

    // Handle form submission to add new pincode
    const handleAddAgentToTerritory = async () => {
        try {
            const agents: string[] = newAgent.map((agent) => agent.id);
            const response = await apiClient.post(`/api/v1/internal_user_territory`, { user_id: agents, territory_id: selectedTerritories });
            handleCloseModal(); // Close the modal
            setNewAgent([]); // Clear the form
            alert("Successfully addeded user to territory");
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
        }
    };

    const fetchUsers = async (searchQuery: string) => {
        try {
            const response = await apiClient.get(`/api/v1/internal_user?username=` + searchQuery);

            const results: { id: string; name: string }[] = [];

            response.data?.forEach((element: InternalUser) => {
                results.push({
                    id: element.user_id,
                    name: element.username
                });
            });
            return results;
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
        }
    }


    return (
        <Modal open={openModal} onClose={handleCloseModal}>
            <Box sx={modalStyle}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography id="modal-title" variant="h6">
                        Add User To Territory
                    </Typography>
                    <IconButton onClick={handleCloseModal}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={filterSectionStyle}>
                    <SearchComponent fetchRecords={fetchUsers} selectedItems={newAgent} setSelectedItems={setNewAgent} />
                </Box>
                <DropDown multiple={true} label="Select Territories" selectedValue={selectedTerritories} items={territories} onSelection={setSelectedTerritories} keyProp="territory_id" valueProp="territory_id" displayProp="name" />
                <Box mt={2} textAlign="right">
                    <Button variant="contained" color="primary" onClick={handleAddAgentToTerritory}>
                        Submit
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default AddAgentsToTerritoryModal;