import React from 'react';
import { Box} from '@mui/material';
import { DataGrid, GridColDef, GridRowId, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

interface SearchResultGridWithActionsProps {
  results: any[];
  getRowId: (row: any) => GridRowId,
  onSelectRow: (id: string[]) => void;
  columns: GridColDef[];
  height?: number | string;
  exportFileName?: string;
  sortModel?: any[];
}

const SearchResultGrid: React.FC<SearchResultGridWithActionsProps> = ({ getRowId, results, onSelectRow, columns, height, exportFileName, sortModel}) => {

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={{
            fileName: exportFileName ? exportFileName : 'export',
        }}/>
      </GridToolbarContainer>
    );
  };

  return (
    <Box sx={{height: height ? height: 400, width: '100%' }}>
      <DataGrid
        getRowId={getRowId}
        rows={results}
        columns={columns}
        checkboxSelection
        sortModel={sortModel}
        slots={{
          toolbar: CustomToolbar,
        }}
        sortingOrder={['asc', 'desc']}
        onRowSelectionModelChange={(newSelection) => {
          onSelectRow(newSelection as string[]);
        }}
      />
    </Box>
  );
};

export default SearchResultGrid;
