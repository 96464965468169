import { Box, Button, Modal } from '@mui/material';
import React, { useState } from 'react';
import Case from '../../interfaces/case';
import ReassignmentModal from '../modals/reassignmentModal';
import StatusUpdateModal from '../modals/statusUpdateModal';
import AdditionalDataModal from '../modals/additionalDataModal';
import DispositionUpdateModal from '../modals/dispositionUpdateModal';
import SeeDetailsDrawer from '../modals/seeDetailsDrawer';
import RoleManagement from '../../security/roleManagement';

interface ReassignmentProps {
    selectedCases: Case[];
}

const GridActions: React.FC<ReassignmentProps> = ({ selectedCases }) => {

    const [reassignment, loadReassignment] = useState<boolean>(false);
    const [interaction, loadInteraction] = useState<boolean>(false);
    const [statusUpdate, loadStatusUpdate] = useState<boolean>(false);
    const [caseDetails, loadCaseDetails] = useState<boolean>(false);
    const [seeDetails, loadSeeDetails] = useState<boolean> (false);
    const [dispositionHistory, loadDispositionHistory] = useState<boolean>(false);
    const [audit, loadAudit] = useState<boolean>(false);

    return (
        <Box display="flex" justifyContent="flex-end" marginY="10px">
            {/* {selectedCases?.length === 1 && <Button variant="contained" color="info" onClick={() => loadCaseDetails(true)} style={{ marginRight: 10 }}>
                Case Details
            </Button>
            }
            {selectedCases?.length === 1 && <Button variant="contained" color="warning" onClick={() => loadDispositionHistory(true)} style={{ marginRight: 10 }}>
                Disposition History
            </Button>}
            {selectedCases?.length === 1 && <Button variant="contained" color="error" onClick={() => loadAudit(true)} style={{ marginRight: 10 }}>
                Audit
            </Button>} */}
            {!RoleManagement.isAgent(localStorage.getItem("role")) && selectedCases?.length > 0 && <Button variant="contained" color="primary" onClick={() => loadReassignment(true)} style={{ marginRight: 10 }}>
                Reassignment
            </Button>}
            {/* {selectedCases?.length === 1 && <Button variant="contained" color="info" onClick={() => loadInteraction(true)} style={{ marginRight: 10 }}>
                Add Disposition
            </Button>} */}
            {/* {selectedCases?.length===1 && <Button variant = "contained" color ="info" onClick={()=> loadSeeDetails(true)} style={{marginRight:10}}>
                See Details
            </Button>                
            } */}
            {!RoleManagement.isAgent(localStorage.getItem("role")) && selectedCases?.length > 0 &&<Button variant="contained" color="secondary" onClick={() => loadStatusUpdate(true)}>
                Status Update
            </Button>}

            {/* Modals */}
            { reassignment && <ReassignmentModal open={reassignment} selectedRows={selectedCases} onClose={() => loadReassignment(false)} />}

            { statusUpdate && <StatusUpdateModal open={statusUpdate} selectedRows={selectedCases} onClose={() => loadStatusUpdate(false)} />}
            
            { interaction && <DispositionUpdateModal open={interaction} selectedRow={selectedCases[0]} onClose={() => loadInteraction(false)} />}

            {/* { audit && <AdditionalDataModal open={audit} title={'Audit'} selectedRow={selectedCases[0]} onClose={() => loadAudit(false)} />} */}

            {/* { caseDetails && <AdditionalDataModal open={caseDetails} title={'Case Detail'} selectedRow={selectedCases[0]} onClose={() => loadCaseDetails(false)} />} */}

            {/* { dispositionHistory && <AdditionalDataModal open={dispositionHistory} title={'Disposition History'} selectedRow={selectedCases[0]} onClose={() => loadDispositionHistory(false)} />} */}
            
            {/* { seeDetails && <SeeDetailsDrawer open={seeDetails} selectedRow={selectedCases[0]} onClose={()=>{loadSeeDetails(false)}} />} */}
        </Box>
    );
}

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
  

export default GridActions;