import { GridColDef } from "@mui/x-data-grid";

// Columns for the DataGrid
const clientColumnDef: GridColDef[] = [
    { field: 'client_id', headerName: 'ID', width: 100 },
    { field: 'client_name', headerName: 'Client Name', width: 200 },
    { field: 'client_contact', headerName: 'Client Contact', width: 200 },
    { field: 'client_address', headerName: 'Client Address', width: 200 },
];

export default clientColumnDef;