export default class RoleManagement {

    static agent_roles = ["Collection Agent", "Call Center Agent"];

    public static isAgent(user_role: string|null): boolean {
        if(user_role) {
            return this.agent_roles.includes(user_role);
        }
        return false;
    }

    public static isAdmin(user_role: string|null): boolean {
        if(user_role) {
            return user_role === "Admin"
        }
        return false;
    }
}