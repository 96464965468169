import React, { useState } from 'react';
import { Chip, Stack } from '@mui/material';
import ChipItem from './chipItem';

interface ChipListProps {
  items: ChipItem[];
  onSelect: (item: ChipItem|null) => void;
}

const Chips: React.FC<ChipListProps> = ({ items, onSelect }) => {
    const [selectedChipId, setSelectedChipId] = useState<string | number | null>(null);

    const handleChipClick = (item: ChipItem) => {
      const isSelected = selectedChipId === item.id;
      setSelectedChipId(isSelected ? null : item.id);
      onSelect(isSelected ? null : item);
    };

  return (
    <Stack direction="row" spacing={1}>
      {items.map((item) => (
        <Chip
          key={item.id}
          label={item.label}
          onClick={() => handleChipClick(item)}
          clickable
          color={item.id === selectedChipId ? 'primary' : 'default'}
        />
      ))}
    </Stack>
  );
};

export default Chips;
