import React from 'react';
import { makeStyles } from '@mui/styles';

interface FieldItemProps {
  fieldName: string;
  value: string | number | null; // You can specify more types as needed
}

const useStyles = makeStyles({
  detailRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 0',
  },
  heading: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#6e6e6e', // Medium gray
    width: '40%', // Adjust width for alignment
  },
  value: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#708090', // Slate gray
    textAlign: 'right', // Align values to the right
    width: '50%', // Adjust width for alignment
  },
  colon: {
    color: '#999', // Medium gray for colon
    textAlign: 'center', // Center the colon
    width: '10px', // Fixed width to ensure it centers correctly
    fontWeight: 'bold',
  },
});

const FieldItem: React.FC<FieldItemProps> = ({ fieldName, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.detailRow}>
      <span className={classes.heading}>{fieldName}</span>
      <span className={classes.colon}>:</span>
      <span className={classes.value}>{value}</span>
    </div>
  );
};

export default FieldItem;
