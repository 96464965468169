import { Box, Button, IconButton, Modal, TextField, Typography } from "@mui/material"
import { useState } from "react";
import modalStyle from "../../styles/modalStyle";
import CloseIcon from '@mui/icons-material/Close';
import apiClient from "../../axiosClient";


interface ModalProps {
    openModal: boolean,
    handleCloseModal: () => void
}

const AddTerritoryModal: React.FC<ModalProps> = ({openModal, handleCloseModal}) => {

    const [territoryName, setTerritoryName] = useState<string>();
    const [territoryCity, setTerritoryCity] = useState<string>();
    const [territoryState, setTerritoryState] = useState<string>();

    // Handle form submission
    const handleSubmit = async () => {
        try {

            if (!territoryName || !territoryCity || !territoryState) {
                alert('Please fill all required values');
                return;
            }
            // Make POST request to add new client (use your real API endpoint)
            const response = await apiClient.post('/api/v1/territory',
                {
                    'name': territoryName,
                    'city': territoryCity,
                    'state': territoryState
                }
            );
            handleCloseModal(); // Close the modal on success
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
        }
    };


    return (
        <Modal open = { openModal } onClose = { handleCloseModal } >
            <Box sx={modalStyle}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography id="modal-title" variant="h6">
                        Add New Territory
                    </Typography>
                    <IconButton onClick={handleCloseModal}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <TextField
                    label="Territory Name"
                    name="name"
                    value={territoryName}
                    onChange={(event) => setTerritoryName(event?.target.value)}
                    fullWidth
                    required
                    margin="normal"
                />
                <TextField
                    label="Territory City"
                    name="address"
                    value={territoryCity}
                    onChange={(event) => setTerritoryCity(event?.target.value)}
                    fullWidth
                    required
                    margin="normal"
                />
                <TextField
                    label="Territory State"
                    name="contact"
                    value={territoryState}
                    onChange={(event) => setTerritoryState(event?.target.value)}
                    fullWidth
                    required
                    margin="normal"
                />
                <Box mt={2} textAlign="right">
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Box>
            </Box>
    </Modal >
    );
}

export default AddTerritoryModal;