import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button, TextField, Typography, IconButton, Modal } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import Territory from '../../interfaces/territory';
import Picklist from '../../interfaces/picklist';
import DropDown from '../baseComponents/dropdown';
import RoleManagement from '../../security/roleManagement';

interface Filter {
  key: string;
  value: string;
  disabled: boolean;
}

interface FilterSectionProps {
  dropdownKeys: string[];
  onSearch: (filters: Filter[]) => void;
}

const FilterSection: React.FC<FilterSectionProps> = ({ dropdownKeys, onSearch }) => {
  const [filters, setFilters] = useState<Filter[]>([{ key: '', value: '', disabled: false }]);

  const dropdownBasedKeys = ['client_id', 'territory_id', 'status', 'loan_type', 'dpd_bucket'];

  const clients = useSelector((state: RootState) => state.clients.clients);
  const territories: Territory[] = useSelector((state: RootState) => state.territories.territories);
  const picklist: Picklist = useSelector((state: RootState) => state.picklists.picklists);

  const [isInitialUpdate, setIsInitialUpdate] = useState<boolean>(true);

  useEffect(() => {
    const initialize = async () => {
      if (RoleManagement.isAgent(localStorage.getItem("role"))) {
        const username = localStorage.getItem("username");
        if (username) {
          await handleFilterChange(0, "assigned_to", username, true);
        }
      }
      setIsInitialUpdate(false);// so that handleSearch runs using useEffect 
    };
  
    initialize();
  }, []); // Empty dependency array to run only on mount just to update the filters state with localstorage

  useEffect(() => {
    if (!isInitialUpdate) {
      handleSearch();
    }
  }, [isInitialUpdate]); // Dependency array to watch isInitialUpdate
  
  

  const handleAddFilter = () => {
    setFilters([...filters, { key: '', value: '', disabled: false }]);
  };

  const handleRemoveFilter = (index: number) => {
    filters.splice(index, 1);
    setFilters([...filters]);
  }

  const handleFilterChange = (index: number, key: string, value: string, disabled = false) => {
    return new Promise<void>((resolve) => {
      const updatedFilters = [...filters];
      updatedFilters[index] = { key, value, disabled };
      console.log(updatedFilters, "updatedfilters");
      setFilters(updatedFilters);
      console.log(filters, "filterin handle");
      resolve();
    });
  };

  const handleReset = () => {
    if (RoleManagement.isAgent(localStorage.getItem("role"))) {
      const username = localStorage.getItem("username");
      if (username) {
        setFilters([{ key: 'assigned_to', value: username, disabled: true }]);
      } else {
        alert("Invalid Session. Please login.");
      }
    } else {
      setFilters([{ key: '', value: '', disabled: false }]);
    }
  };

  const handleSearch = () => {
    onSearch(filters);
  };

  return (
    <Box mb={3} border={1} borderColor="grey.300" p={2} borderRadius={2}>
      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
        <Button variant="contained" onClick={handleSearch}>
          Apply
        </Button>
        <Button variant="outlined" onClick={handleReset}>
          Reset
        </Button>
      </Box>
      {filters.map((filter, index) => (
        <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, marginBottom: 2 }}>
          <Box width={'100%'}>
            <DropDown label="Key" items={dropdownKeys} selectedValue={filter.key} disabled={filter.disabled}
              onSelection={(value) => handleFilterChange(index, value, filter.value)} />
          </Box>
          <Box width={'100%'} sx={{
            flex: '5 1 0%',
            pointerEvents: filter.disabled ? 'none' : 'auto',
            opacity: filter.disabled ? 0.6 : 1
          }}>
            {!dropdownBasedKeys.includes(filter.key) &&
              <TextField
                margin='normal'
                label="Value"
                value={filter.value}
                onChange={(e) => handleFilterChange(index, filter.key, e.target.value)}
                fullWidth
              />}
            {filter.key === 'client_id' && <DropDown label="Select Client" items={clients} selectedValue={filter.value}
              onSelection={(value) => handleFilterChange(index, filter.key, value)} keyProp="client_id" valueProp="client_id" displayProp="client_name" />}
            {filter.key === 'territory_id' && <DropDown label="Select Territory" items={territories} selectedValue={filter.value}
              onSelection={(value) => handleFilterChange(index, filter.key, value)} keyProp="territory_id" valueProp="territory_id" displayProp="name" />}
            {filter.key === 'status' && <DropDown label="Select Status" items={picklist.STATUS} selectedValue={filter.value}
              onSelection={(value) => handleFilterChange(index, filter.key, value)} />}
            {filter.key === 'loan_type' && <DropDown label="Select Loan Type" items={picklist.LOAN_TYPE} selectedValue={filter.value}
              onSelection={(value) => handleFilterChange(index, filter.key, value)} />}
            {filter.key === 'dpd_bucket' && <DropDown label="Select DPD Bucket" items={picklist.DPD_BUCKET} selectedValue={filter.value}
              onSelection={(value) => handleFilterChange(index, filter.key, value)} />}
          </Box>
          <Box sx={{ flex: '2 1 0%' }}>
            {index === filters.length - 1 ? (
              <IconButton onClick={handleAddFilter} sx={{ marginBottom: 2 }}>
                <AddIcon />
              </IconButton>
            ) : (
              <IconButton disabled={filter.disabled} onClick={() => handleRemoveFilter(index)} sx={{ marginBottom: 2 }}>
                <RemoveIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      ))}



    </Box>
  );
};

export default FilterSection;
