import React, { useContext, useEffect, useState } from 'react';
import {
    Container,
    Typography,
    TextField,
    Button,
    IconButton,
    MenuItem,
    Box,
    Modal,
    Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import { useNavigate, useParams } from 'react-router-dom';
// import { GlobalDataContext } from '../context/GlobalDataContext';
// import { CaseField } from '../models/CaseField';
// import { CaseDetailRequest } from '../models/CaseDetailRequest';
// import { updateCaseDetails } from '../services/caseDetailService';
// import CustomButton from '../components/CustomButton';
// import HeaderComponent from '../components/HeaderComponent';
import caseColumnDef from '../../colDef/caseColumnDef';

import modalStyle from "../../styles/modalStyle";

import CaseDetail from '../../interfaces/caseDetail';

import Field from "../../interfaces/field";
import Case from '../../interfaces/case';
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import apiClient from '../../axiosClient';
// import Modal from '@mui/material';



const NoBackdrop = () => null;

interface FieldByUser {
    fieldName: string;
    value: string;
}

interface CaseDetailRequest {
    case_id: string;
    client_id: string;
    field_id: string;
    field_value: string;
}

interface AddCaseDetailsModalProps {
    open: boolean;
    onClose: () => void;
    selectedRow: Case;
}

const AddCaseDetailsModal: React.FC<AddCaseDetailsModalProps> = ({ open, onClose, selectedRow }) => {
    //   const { caseItem } = useParams(); // Assuming caseItem is passed as a route parameter
    const [fields, setFields] = useState<FieldByUser[]>([{ fieldName: '', value: '' }]);// fields added by the user
    const [fieldOptions, setFieldOptions] = useState<Field[]>([]);
    const [error, setError] = useState<string>('');
    const standardFields = useSelector((state: RootState) => state.fields.fields);


    useEffect(() => {
        const loadCases = async () => {
            try {
                // const additionalFieldList: CaseField[] = globalData ? globalData.additionalFieldList : [];
                setFieldOptions(
                    standardFields.filter(
                        (field) => field.field_name === 'address_2' || field.field_name === 'contact_number_2'
                    )
                );
            } catch (err) {
                setError('Failed to load field options.');
            }
        };

        loadCases();
    }, [standardFields]);

    const addField = () => {
        setFields([...fields, { fieldName: '', value: '' }]);
    };

    const handleFieldChange = (index: number, key: keyof FieldByUser, value: string) => {
        const updatedFields = [...fields];
        updatedFields[index][key] = value;
        setFields(updatedFields);
    };

    const removeField = (index: number) => {
        const updatedFields = fields.filter((_, i) => i !== index);
        setFields(updatedFields);
    };

    const handleSave = async () => {
        if (fields.length === 0) {
            alert('Please add at least one field to save.');
            return;
        }

        try {
            const caseDetailRequest: CaseDetailRequest[] = fields.map((field) => {
                const fieldOption = fieldOptions.find(option => option.field_name === field.fieldName);
                if (!fieldOption) {
                    throw new Error(`Field ID not found for field name: ${field.fieldName}`);
                }

                return {
                    case_id: selectedRow.case_id,
                    client_id: selectedRow.client_id,
                    // field_name: field.fieldName,
                    field_id: fieldOption.field_id,
                    field_value: field.value,
                    // created_date: new Date().toISOString(),
                    // created_by: 'current_user_id', // Replace with actual user ID
                    // updated_date: new Date().toISOString(),
                    // updated_by: 'current_user_id', // Replace with actual user ID
                    // field_type: fieldOption.field_type
                };
            });
            try {

                const status = await apiClient.post('/api/v1/case_detail', caseDetailRequest);

            } catch (error: any) {
                if (error.response) {
                    alert('Error response data:' + error.response.data.message);
                } else if (error.request) {
                    alert('No response received:' + error.request);
                } else {
                    alert('Error message:' + error.message);
                }
            }



        } catch (error) {
            console.error('Error saving case details:', error);
            alert('An error occurred while saving case details.');
        }
    };

    return (
        <Modal open={open} onClose={onClose} sx={modalStyle} disableEnforceFocus
            BackdropComponent={NoBackdrop}
        >

                <Box >
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 8, right: 8 }           

                }
                >
                    <CloseIcon color='error' fontSize='large'/>
                </IconButton>
                <Divider sx={{mt:4}}></Divider>
                {fields.map((field, index) => (
                    <Box key={index} display="flex" alignItems="center">
                        <TextField
                            select
                            label="Field Type"
                            value={field.fieldName}
                            onChange={(e) => handleFieldChange(index, 'fieldName', e.target.value)}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        >
                            <MenuItem value="">
                                <em>Select Field Type</em>
                            </MenuItem>
                            {fieldOptions.map((option) => (
                                <MenuItem key={option.field_id} value={option.field_name}>
                                    {option.field_description}
                                </MenuItem>
                            ))}
                        </TextField>
                        <IconButton onClick={() => removeField(index)} color="error">
                            <CloseIcon />
                        </IconButton>
                        <TextField
                            label="Field"
                            value={field.value}
                            onChange={(e) => handleFieldChange(index, 'value', e.target.value)}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            type={field.fieldName === 'contact_number_2' ? 'tel' : 'text'}
                        />
                    </Box>
                ))}
                <Button
                    onClick={addField}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                >
                    + Add More Fields
                </Button>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                    fullWidth
                >
                    SAVE DETAILS
                </Button>
            </Box>

        </Modal>
    );
};

export default AddCaseDetailsModal;
