import React, { useState} from 'react';
import { Box, TextField, IconButton, CircularProgress, MenuItem, Chip, Select, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface SearchComponentProps {
    fetchRecords: (query: string) => Promise<{ id: string; name: string }[]|undefined>;
    selectedItems: { id: string; name: string }[];
    setSelectedItems: (items: { id: string; name: string }[]) => void
}

const SearchComponent: React.FC<SearchComponentProps> = ({ fetchRecords, selectedItems, setSelectedItems }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState<{ id: string; name: string }[]>([]);
    const [currentSelection, setCurrentSelection] = useState<{ id: string; name: string }[]>([]);

    // Fetch records when search is triggered
    const handleSearch = async () => {
        if (searchQuery.trim() === '' || searchQuery.trim()?.length <= 3) {
            alert("Atleast three characters are required to make a search");
            return;
        }

        setLoading(true);
        const records = await fetchRecords(searchQuery);
        if (records == undefined) {
            alert("Could not load results");
        } else {
            setResults(records);
        }
        setLoading(false);
    };

    // Handle removing a selected item
    const handleDelete = (item: { id: string; name: string }) => {
        const newItems = selectedItems.filter((selected) => selected.id !== item.id)
        setSelectedItems(newItems);
    };

    const handleSelections = (selectedValues: { id: string; name: string }[]) => {
        const newItems: { id: string; name: string }[] = [];
        for (const selectedValue of selectedValues) {
            const itemFound = selectedItems.find((selectedItem) => selectedItem.id === selectedValue.id);
            if (!itemFound) {
                newItems.push(selectedValue);
            }
        }
        setSelectedItems([...selectedItems, ...newItems]);
        setCurrentSelection(selectedValues);
    }

    return (
        <Box sx={{ width: '100%'}}>
            {/* Display Selected Items as Chips */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', marginBottom: 2 }}>
                {selectedItems.map((item) => (
                    <Chip
                        key={item.id}
                        label={item.name}
                        onDelete={() => handleDelete(item)}
                        sx={{ margin: 0.5 }}
                    />
                ))}
            </Box>

            {/* Search Input with Search Icon */}
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2}}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') handleSearch(); // Trigger search on Enter
                    }}
                    slotProps={{
                        input: {
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSearch}>
                                        {loading ? <CircularProgress size={24} /> : <SearchIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        },
                    }}
                />
            </Box>

            {/* <DropDown items={results} keyProp='id' multiple={true} selectedValue={selectedItems} valueProp='name' displayProp='name' onSelection={handleSelectChange} label='Select'/> */}
            <Select
                multiple
                fullWidth
                displayEmpty
                value={currentSelection.map(item => item.id)} // Extract the ids for value
                onChange={(event) => {
                    const selectedIds = event.target.value as string[];
                    const selectedObjects = results.filter((result) => selectedIds.includes(result.id));
                    handleSelections(selectedObjects);
                }}
                renderValue={(selected) =>
                    selected.length === 0 ? 'Select items' : selected.map((id: string) => results.find(item => item.id === id)?.name).join(', ')
                }
            >
                {results.map((result) => (
                    <MenuItem key={result.id} value={result.id}>
                        {result.name}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
};

export default SearchComponent;
