import { GridRenderCellParams } from '@mui/x-data-grid';
import { Box, Button, IconButton } from '@mui/material';
import React, { useState } from 'react';
import SeeDetailsDrawer from '../modals/seeDetailsDrawer';
import PhoneIcon from '@mui/icons-material/Phone';

interface ActionsCellProps {
    params: GridRenderCellParams<any, any, any>;
}

const ActionsCell: React.FC<ActionsCellProps> = ({ params }) => {

    const [seeDetails, loadSeeDetails] = useState<boolean>(false);
    const handleCalling = () => {
        // here we will write the function to impliment calling we have access to the id of selected case and the full row we can use to give the call 
    };
    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'flex-end',
                pr: 2,
                '&:hover button': {
                    visibility: 'visible',
                    opacity: 1,
                    transform: 'translateX(0)',
                },
            }}
        >
           
            <Button
                variant="outlined"
                color="secondary"
                size="medium"
                // sx={{
                //   visibility: 'hidden',
                //   opacity: 0,
                //   transition: 'all 0.3s ease-in-out',
                //   transform: 'translateX(100%)',
                //   position: 'absolute',
                //   right: 0,
                // }}
                onClick={() => loadSeeDetails(true)}
            >
                View Case-Details
            </Button>

            {seeDetails && <SeeDetailsDrawer open={seeDetails} selectedRow={params.row} onClose={() => { loadSeeDetails(false) }} />}

        </Box>
    );
};



export default ActionsCell;
