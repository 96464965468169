import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import modalStyle from "../../styles/modalStyle";
import DropDown from "../baseComponents/dropdown";
import SearchResultGrid from "../searchResultGrid/searchResultGrid";
import CloseIcon from '@mui/icons-material/Close';
import Territory from "../../interfaces/territory";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useState } from "react";
import Pincode from "../../interfaces/pincode";
import pincodeColumnDefs from "../../colDef/pincodeColumnDef";
import apiClient from "../../axiosClient";

interface MovePincodeModal {
    open: boolean,
    onClose: () => void,
    selectedRows: Pincode[]
}

const MovePinCodeModal: React.FC<MovePincodeModal> = ({open, onClose, selectedRows}) => {
    const [selectedTerritoy, setSelectedTerritory] = useState<string>();

    let territories: Territory[] = useSelector((state: RootState) => state.territories.territories);

    // Handle form submission to add new pincode
    const handleMovePincode = async () => {
        try {
            const pincodes: string[] = selectedRows.map((selectedRow: Pincode) => selectedRow.pin_code_id);
            const response = await apiClient.post(`/api/v1/pin_code`, { pin_code_id: pincodes, territory_id: selectedTerritoy });
            onClose(); // Close the modal
            setSelectedTerritory(undefined); // Clear the form
            alert("Successfully moved pincode to new territory");
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography id="modal-title" variant="h6">
                        Move Pincode Between Territory
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DropDown label="Select New Territory" items={territories} selectedValue={selectedTerritoy} 
                onSelection={(value) => setSelectedTerritory(value)} keyProp="territory_id" valueProp="territory_id" displayProp="name"/>
                <Box mt={3}>
                    <Typography variant="subtitle1">Selected Rows:</Typography>
                    <SearchResultGrid
                        getRowId={(row) => row.pin_code_id}
                        results={selectedRows}
                        onSelectRow={() => { }}
                        columns={pincodeColumnDefs}
                    />
                </Box>
                <Box mt={3} textAlign="right">
                    <Button variant="contained" color="primary" onClick={handleMovePincode}>Submit</Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default MovePinCodeModal;