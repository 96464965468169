import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Disposition from '../../interfaces/disposition';

interface DispositionMappingState {
    dispositions: Disposition[],
}

const initialState: DispositionMappingState = {
    dispositions: []
}

export const dispositionMappingSlice = createSlice({
    name: 'dispositionMapping',
    initialState,
    reducers: {
        loadDispositionMapping: (state, action: PayloadAction<Disposition[]>) => {
            state.dispositions = action.payload
        }
    }
});

export const {loadDispositionMapping} = dispositionMappingSlice.actions;

export default dispositionMappingSlice.reducer;