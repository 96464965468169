import { Box, Button, IconButton, Modal, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import apiClient from "../../axiosClient";
import SettlementRule from "../../interfaces/settlementRule";
import SearchResultGrid from "../searchResultGrid/searchResultGrid";
import settlementRuleColumnDef from "../../colDef/settlementRuleColumnDef";
import modalStyle from "../../styles/modalStyle";
import CloseIcon from '@mui/icons-material/Close';
import DropDown from "../baseComponents/dropdown";
import filterSectionStyle from "../../styles/filterSectionStyle";

const ManageSettlementRule: React.FC = () => {

    const [selectedClient, setSelectedClient] = useState<string>();
    const [settlementRules, setSettlementRules] = useState<SettlementRule[]>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);

    const [newRuleClient, setNewRuleClient] = useState<string>();
    const [newRuleBucket, setNewRuleBucket] = useState<string>();
    const [newRulePercentage, setNewRulePercentage] = useState<number>();

    const clients = useSelector((state: RootState) => state.clients.clients);
    const picklist = useSelector((state: RootState) => state.picklists.picklists);

    // Handle modal open/close
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const getClientSettlementRule = async (selectedClient: string) => {
        setSelectedClient(selectedClient);

        try {
            const response = await apiClient.get('/api/v1/settlement_rule?client_id=' + selectedClient);
            if (response.status === 200) {
                setSettlementRules(response.data);
            } else {
                alert("Error while fetching settlement rule: " + response.data.message)
            }
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
        }
    }

    const checkAndSetSettlementPercentage = (value: string) => {
        const num = Number(value);
        if (num < 0 || num > 100) {
            alert("Percentage needs to be in between 0-100");
        } else {
            setNewRulePercentage(Number(value));
        }
    }

    const addNewSettlementRule = async () => {
        try {
            const response = await apiClient.post('/api/v1/settlement_rule', {
                client_id: newRuleClient,
                dpd_bucket: newRuleBucket,
                settlement_percentage: newRulePercentage
            });
            if (response.status === 200) {
                alert("Settlement rule added successfully");
            } else {
                alert("query failed");
            }
            setNewRuleClient(undefined);
            setNewRuleBucket(undefined);
            setNewRulePercentage(undefined);
            handleCloseModal();
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
        }
    }

    return (
        <Box m={2}>
            <Box sx={filterSectionStyle}>
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        onClick={handleOpenModal}
                    >
                        Add Settlement Rule
                    </Button>
                </Box>
                <DropDown label="Select Client" items={clients} selectedValue={selectedClient} onSelection={getClientSettlementRule} keyProp="client_id" valueProp="client_id" displayProp="client_name" />
            </Box>

            <SearchResultGrid
                height='70vh'
                getRowId={(row: SettlementRule) => row.rule_id}
                results={settlementRules}
                onSelectRow={() => { }}
                columns={settlementRuleColumnDef}
                exportFileName='settlement_rules'
            />

            <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={modalStyle}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography id="modal-title" variant="h6">
                            Add New Settlement Rule
                        </Typography>
                        <IconButton onClick={handleCloseModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <DropDown label="Select Client" items={clients} selectedValue={newRuleClient} onSelection={setNewRuleClient} keyProp="client_id" valueProp="client_id" displayProp="client_name" />
                    <DropDown label="Select DPD Bucket" items={picklist.DPD_BUCKET} selectedValue={newRuleBucket} onSelection={setNewRuleBucket} />
                    <Box mt={2} display="flex" justifyContent="space-between">
                        <Box width="100%">
                            <TextField
                                label="Settlement Percentage"
                                type="number"
                                value={newRulePercentage}
                                onChange={(e) => checkAndSetSettlementPercentage(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </Box>
                    </Box>
                    <Box mt={2} textAlign="right">
                        <Button variant="contained" color="primary" onClick={addNewSettlementRule}>
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default ManageSettlementRule;