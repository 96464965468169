import React from 'react';
import { useLocation } from 'react-router-dom';

const ErrorPage: React.FC = () => {
  const location = useLocation();
  const { message, errorCode } = location.state || {};
  return (
    <div>
      <h1>Error!!</h1>
      {message && <h2>{message}</h2>}
      {errorCode && <h2>Error Code: {errorCode}</h2>}
    </div>
  );
};

export default ErrorPage;