import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Picklist from '../../interfaces/picklist'

interface PicklistState {
    picklists: Picklist,
}

const initialState: PicklistState = {
    picklists: {
        DPD_BUCKET: [],
        STATUS: [],
        LOAN_TYPE: [],
        USER_ROLE: [],
        VALUE_TYPE: []
    }
}

export const picklistSlice = createSlice({
    name: 'picklist',
    initialState,
    reducers: {
        loadPicklist: (state, action: PayloadAction<Picklist>) => {
            state.picklists = action.payload
        }
    }
});

export const {loadPicklist} = picklistSlice.actions;

export default picklistSlice.reducer;