import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Territory from '../../interfaces/territory';

interface TerritoryState {
    territories: Territory[],
}

const initialState: TerritoryState = {
    territories: []
}

export const territorySlice = createSlice({
    name: 'territories',
    initialState,
    reducers: {
        loadTerritories: (state, action: PayloadAction<Territory[]>) => {
            state.territories = action.payload
        }
    }
});

export const {loadTerritories} = territorySlice.actions;

export default territorySlice.reducer;