import { AppBar, Toolbar, Typography, Box, IconButton, Tooltip } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/accio-finserv-logo-text-variant.png';

function Header() {
  const navigate = useNavigate();
  
  return (
    <AppBar position="static" sx={{ backgroundColor: '#2c3e50', padding: '10px 0' }}>
      <Toolbar>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          {/* <Typography
            variant="h4"
            component="div"
            sx={{
              color: '#ecf0f1',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '0.1rem',
            }}
          >
            Collect Edge
          </Typography> */}
          <img
        src={logo}
        alt="Logo"
        style={{
          // width: '50px',  // Adjust size
          height: '64px',
          // marginRight: '10px',  // Space between logo and button
        }}
      />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
