import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Field from '../../interfaces/field';

interface FieldState {
    fields: Field[],
}

const initialState: FieldState = {
    fields: []
}

export const fieldSlice = createSlice({
    name: 'fields',
    initialState,
    reducers: {
        loadFields: (state, action: PayloadAction<Field[]>) => {
            state.fields = action.payload
        }
    }
});

export const {loadFields} = fieldSlice.actions;

export default fieldSlice.reducer;