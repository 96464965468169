import React, { useState } from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchResultGrid from '../searchResultGrid/searchResultGrid';
import modalStyle from '../../styles/modalStyle';
import caseColumnDef from '../../colDef/caseColumnDef';
import apiClient from '../../axiosClient';
import Case from '../../interfaces/case';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import DropDown from '../baseComponents/dropdown';

interface StatusUpdateModalProps {
    open: boolean;
    onClose: () => void;
    selectedRows: any[];
}

const StatusUpdateModal: React.FC<StatusUpdateModalProps> = ({ open, onClose, selectedRows }) => {
    const [status, setStatus] = useState('');

    const picklists = useSelector((state: RootState) => state.picklists.picklists);

    const handleSubmit = async () => {
        try {
            const body = selectedRows.map((row: Case) => {
                return {
                    case_id: row.case_id,
                    client_id: row.client_id,
                    status_old: row.status,
                    status: status
                }
            })
            const response = await apiClient.post('api/v1/case/status_update', body);
            if (response.status === 200) {
                alert("Status update successful");
            } else {
                alert("Status update failed");
            }
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
        }
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography id="modal-title" variant="h6">
                        Status Update
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DropDown label="Select Status" items={picklists.STATUS} selectedValue={status} 
                onSelection={(value) => setStatus(value)}/>
                <Box mt={3}>
                    <Typography variant="subtitle1">Selected Rows:</Typography>
                    <SearchResultGrid
                        getRowId={(row) => row.case_id}
                        results={selectedRows}
                        onSelectRow={() => { }}
                        columns={caseColumnDef}
                    />
                </Box>
                <Box mt={3} textAlign="right">
                    <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default StatusUpdateModal;
