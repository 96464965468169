import { Box, Button, IconButton, Modal, TextField, Typography } from "@mui/material";
import apiClient from "../../axiosClient";
import { useEffect, useState } from "react";
import Field from "../../interfaces/field";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import SearchResultGrid from "../searchResultGrid/searchResultGrid";
import fieldColumnDef from "../../colDef/fieldColumnDef";
import modalStyle from "../../styles/modalStyle";
import CloseIcon from '@mui/icons-material/Close';
import DropDown from "../baseComponents/dropdown";
import filterSectionStyle from "../../styles/filterSectionStyle";

const ManageField: React.FC = () => {

    const [searchResults, setSearchResults] = useState<Field[]>([]);
    const [selectedClient, setSelectedClient] = useState<string>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [newFieldName, setNewFieldName] = useState<string>();
    const [newFieldType, setNewFieldType] = useState<string>();
    const [isNewFieldStandard, setIsNewFieldStandard] = useState<boolean>(true);
    const [newFieldDescription, setNewFieldDescription] = useState<string>();
    const [newFieldValueType, setNewFieldValueType] = useState<string>();
    const [newFieldClient, setNewFieldClient] = useState<string>();
    
    const standardFields = useSelector((state: RootState) => state.fields.fields);
    const clients = useSelector((state: RootState) => state.clients.clients);

    const picklists = useSelector((state: RootState) => state.picklists.picklists);

    // Handle modal open/close
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);


    useEffect(() => {
        setSearchResults(standardFields);
    }, []);

    const onReset = () => {
        setSelectedClient('');
        setSearchResults(standardFields);
    }

    const handleIsFieldStandardSelection = (value: string) => {
        if (value === "true") {
            setNewFieldClient(undefined);
            setIsNewFieldStandard(true);
        } else {
            setIsNewFieldStandard(false);
        }
    }

    const handleSearch = async () => {
        try {
            const response = await apiClient.get('/api/v1/field?client_id=' + selectedClient);
            if (response.status === 200) {
                setSearchResults(response.data);
            } else {
                alert("query failed");
            }
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
        }
    };

    const addNewField = async () => {
        try {
            const response = await apiClient.post('/api/v1/field', {
                client_id: newFieldClient,
                field_name: newFieldName,
                field_description: newFieldDescription,
                field_value_type: newFieldValueType,
                field_type: newFieldType,
                is_standard: isNewFieldStandard
            });
            if (response.status === 200) {
                alert("Field added successfully");
            } else {
                alert("query failed");
            }
            setNewFieldName(undefined);
            setNewFieldDescription(undefined);
            setNewFieldClient(undefined);
            setNewFieldType(undefined);
            setNewFieldValueType(undefined);
            setIsNewFieldStandard(true);
            handleCloseModal();
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
        }
    }

    return (
        <Box m={2}>
            <Box sx={filterSectionStyle}>
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        onClick={handleOpenModal}
                    >
                        Add Field
                    </Button>
                </Box>

                <DropDown label="Select Client" items={clients} selectedValue={selectedClient} onSelection={setSelectedClient} keyProp="client_id" valueProp="client_id" displayProp="client_name" />

                <Box display="flex" marginY='10px'>
                    <Button
                        variant="contained"
                        onClick={handleSearch}
                        disabled={!selectedClient}
                        style={{ marginRight: 10 }}
                    >
                        Search
                    </Button>
                    <Button
                        variant="contained"
                        onClick={onReset}
                    >
                        Reset
                    </Button>
                </Box>
            </Box>

            <SearchResultGrid
                height='60vh'
                getRowId={(row: Field) => row.field_id}
                results={searchResults}
                onSelectRow={() => { }}
                columns={fieldColumnDef}
                exportFileName='fields'
            />

            {/* Modal for adding a new pincode */}
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={modalStyle}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography id="modal-title" variant="h6">
                            Add New Field
                        </Typography>
                        <IconButton onClick={handleCloseModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box mt={2} display="flex" justifyContent="space-between">
                        <Box width="48%">
                            <DropDown label="Is Standard" items={["true", "false"]} selectedValue={isNewFieldStandard} onSelection={handleIsFieldStandardSelection} />
                        </Box>
                        <Box width="48%">
                            {!isNewFieldStandard && <DropDown label="Select Client" items={clients} selectedValue={newFieldClient} onSelection={setNewFieldClient} keyProp="client_id" valueProp="client_id" displayProp="client_name" />}
                        </Box>
                    </Box>
                    <Box mt={2} display="flex" justifyContent="space-between">
                        <Box width="30%">
                            <TextField
                                label="Field Name"
                                value={newFieldName}
                                onChange={(e) => setNewFieldName(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </Box>
                        <Box width="30%">
                            <DropDown label="Field Type" items={newFieldClient ? ["PRIMARY", "SECONDARY"] : ["SECONDARY"]} selectedValue={newFieldType} onSelection={setNewFieldType} />
                        </Box>
                        <Box width="30%">
                            <DropDown label="Select Value Type" items={picklists?.VALUE_TYPE} selectedValue={newFieldValueType}
                                onSelection={(value) => setNewFieldValueType(value)} />
                        </Box>
                    </Box>
                    <Box mt={2} display="flex" justifyContent="space-between">
                        <Box width="100%">
                            <TextField
                                label="Field Description"
                                value={newFieldDescription}
                                onChange={(e) => setNewFieldDescription(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </Box>
                    </Box>
                    <Box mt={2} textAlign="right">
                        <Button variant="contained" color="primary" onClick={addNewField}>
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default ManageField;
