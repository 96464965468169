import { Box, Button} from "@mui/material";

import SearchResultGrid from "../searchResultGrid/searchResultGrid";
import Pincode from "../../interfaces/pincode";
import { useEffect, useState } from "react";
import apiClient from "../../axiosClient";
import AddPinCodeModal from "../modals/addPincodeModal";
import pincodeColumnDefs from "../../colDef/pincodeColumnDef";
import MovePinCodeModal from "../modals/movePincodeModal";

interface PincodeProps {
    selectedTerritory: string | undefined,
    load: boolean
}

const ManagePincodes: React.FC<PincodeProps> = ({ selectedTerritory, load }) => {
    const [pincodes, setPincodes] = useState<Pincode[]>([]);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openMoveModal, setOpenMoveModal] = useState(false);
    
    const [selectedPincode, setSelectedPincode] = useState<Pincode[]>([]);

    useEffect(() => {
        const handleFetchPincodes = async () => {
            try {
                const response = await apiClient.get(`/api/v1/pin_code?territory_id=` + selectedTerritory); // Replace with your API endpoint
                setPincodes(response.data as Pincode[]);
            } catch (error: any) {
                if (error.response) {
                    alert('Error response data:' + error.response.data.message);
                } else if (error.request) {
                    alert('No response received:' + error.request);
                } else {
                    alert('Error message:' + error.message);
                }
            }
        };
        handleFetchPincodes();
    }, [selectedTerritory, load])

    // Handle modal open/close
    const handleOpenAddModal = () => setOpenAddModal(true);
    const handleCloseAddModal = () => setOpenAddModal(false);

    const handleOpenMoveModal = () => setOpenMoveModal(true);
    const handleCloseMoveModal = () => setOpenMoveModal(false);

    const handleSelectRow = (ids: string[]) => {
        setSelectedPincode(pincodes.filter((row: Pincode) => ids.includes(row.pin_code_id)));
    };

    return (
        <Box>
            <Box display="flex" justifyContent="flex-end" marginY="10px">
                <Button
                    variant="contained"
                    onClick={handleOpenAddModal}
                    disabled={!selectedTerritory}
                    sx={{marginRight: '10px'}}
                >
                    Add New Pincode
                </Button>

                <Button
                    variant="contained"
                    onClick={handleOpenMoveModal}
                    disabled={selectedPincode?.length === 0}
                >
                    Move Pincode
                </Button>
            </Box>

            <SearchResultGrid
                getRowId={(row: Pincode) => row.pin_code_id}
                results={pincodes}
                onSelectRow={handleSelectRow}
                columns={pincodeColumnDefs}
                exportFileName='pincodes'
            />

            <AddPinCodeModal openModal={openAddModal} handleCloseModal={handleCloseAddModal} selectedTerritory={selectedTerritory}/>
            <MovePinCodeModal open={openMoveModal} onClose={handleCloseMoveModal} selectedRows={selectedPincode}/>
        </Box>
    );
}

export default ManagePincodes;