import React, { useState, useEffect } from 'react';
// import { Modal, Box, Typography, Button, IconButton, Drawer, Toolbar } from '@mui/material';
import { Box, Drawer, Toolbar, Typography, IconButton, Button, Collapse, List, ListItem, ListItemText, ListItemIcon, Divider, Tab, Tabs, FormControl, InputLabel, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PhoneIcon from '@mui/icons-material/Phone';
import TabPanel from '@mui/lab/TabPanel';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import Audit from '../../interfaces/audit';
import CaseDetail from '../../interfaces/caseDetail';
import Interaction from '../../interfaces/interaction';
import Case from '../../interfaces/case';
import apiClient from '../../axiosClient';
import CollapsibleComponent from '../baseComponents/collapsibleComponents';
import { DISPOSITION_COLOR_MAP } from '../../constants/caseDetailsConstants';
import CallCustomerSection from '../call/callingSection';

import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import DropDown from '../baseComponents/dropdown';
import FieldItem from '../baseComponents/FieldItem';
import Disposition from '../../interfaces/disposition';
import SubDisposition from '../../interfaces/sub_disposition';
import AddCaseDetailsModal from './addCaseDetailModal';
import InteractionValue from '../../interfaces/interactionValue';


const useStyles = makeStyles((theme: any) => ({
    drawer: {
        width: 300,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 300,
        boxSizing: 'border-box',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    scrollViewContainer: {
        padding: '16px',
    },
    phoneContainer: {
        display: 'flex',
        gap: '16px',
        alignItems: 'center',
        marginBottom: '16px',
    },
    button: {
        marginLeft: '8px',
    },
    icon: {
        marginLeft: '8px',
    },
    locationItem: {
        display: 'flex',
        alignItems: 'center',
    },
    interactionContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    interactionText: {
        flexGrow: 1,
    },
}));


interface SeeDetailsDrawerProps {
    open: boolean;
    onClose: () => void;
    selectedRow: any;
}


const SeeDetailsDrawer: React.FC<SeeDetailsDrawerProps> = ({ open, onClose, selectedRow }) => {
    const [status, setStatus] = useState('');
    const drawerWidth: string = '1040px';
    const classes = useStyles();
    const [auditData, setAuditData] = useState<Audit[]>([]);
    const [caseDetailData, setCaseDetailData] = useState<CaseDetail[]>([]);
    const [interactionData, setInteractionData] = useState<Interaction[]>([]);
    const [disposition, setDisposition] = useState('');
    const [subDisposition, setSubDisposition] = useState('');
    const [comment, setComment] = useState('');
    const [latitude, setLatitude] = useState<string | null>(null);
    const [longitude, setLongitude] = useState<string | null>(null);
    const [customer_latitude, setCustomer_Latitude] = useState<string | null>(null);
    const [customer_longitude, setCustomer_Longitude] = useState<string | null>(null);
    const [ptpAmount, setPtpAmount] = useState<string | null>(null);
    const [ptpDate, setPtpDate] = useState<string | null>(null);
    const [followupDate, setFollowupDate] = useState<string | null>(null);
    const [openCallingSection, setOpenCallingSection] = useState<boolean>(true);
    const [addCaseDetailModalOpen, setAddCaseDetailModalOpen] = useState<boolean>(false);


    const dispositionMapping = useSelector((state: RootState) => state.dispositionMapping.dispositions);




    const getAdditionalData = (response: any, type: string) => {
        const additionalData: any[] = [];
        response.forEach((element: any | any[]) => {
            if (type === "Audit") {
                const data: Audit = {
                    audit_id: element['audit']['audit_id'],
                    field_name: element['field']['field_name'],
                    old_field_value: element['audit']['old_field_value'],
                    new_field_value: element['audit']['new_field_value'],
                    created_date: element['audit']['created_date'],
                    created_by: element['audit']['created_by'],
                }
                additionalData.push(data);
            } else if (type === 'CaseDetail') {
                const data: CaseDetail = {
                    case_detail_id: element['case_detail']['case_detail_id'],
                    field_name: element['field']['field_name'],
                    created_date: element['case_detail']['created_date'],
                    created_by: element['case_detail']['created_by'],
                    field_value: element['case_detail']['field_value'],
                    updated_date: element['case_detail']['updated_date'],
                    updated_by: element['case_detail']['updated_by'],
                    field_type: element['field']['field_value_type']
                }
                additionalData.push(data);
                const longitudeField = additionalData.find((item: CaseDetail) => item.field_name === 'longitude');
                const latitudeField = additionalData.find((item: CaseDetail) => item.field_name === 'latitude');

                setCustomer_Longitude(longitudeField?.value);
                setCustomer_Latitude(latitudeField?.value);

            } else {
                const data: Interaction = {
                    interaction_history_id: element['interaction_history_id'],
                    created_by: element['created_by'],
                    disposition: element['disposition'],
                    sub_disposition: element['sub_disposition'],
                    interaction_mode: element['interaction_mode'],
                    latitude: element['latitude'],
                    longitude: element['longitude'],
                    image_url: element['image_url'],
                    comment: element['comment'],
                    disposition_value: JSON.stringify(element['disposition_value']),
                    created_date: element['created_date'],
                }
                additionalData.push(data);
            }
        });
        // console.log(additionalData);
        return additionalData;
    }

    const fetchAdditionalData = async (api: string, type: string) => {
        try {
            const response = await apiClient.get('/api/v1/' + api + '?case_id=' + selectedRow.case_id);
            if (response.status === 200) {
                const additionalData = getAdditionalData(response.data, type);
                // console.log(response.data, "resposedata")
                if (type === "Audit") {
                    setAuditData(additionalData);
                } else if (type === "CaseDetail") {
                    setCaseDetailData(additionalData);

                } else if (type === "Interaction") {
                    setInteractionData(additionalData);
                }
            } else {
                alert("Error while loading additional data list: " + "fetching CaseDetail or Interaction data" + ", Error: " + response.data.message);
            }
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
        }
    };
    useEffect(() => {
        // fetchAdditionalData('audit', 'Audit');
        fetchAdditionalData('case_detail', 'CaseDetail');
        fetchAdditionalData('interaction_history', 'Interaction');

        // console.log("interaction data omesh", selectedRow, caseDetailData);
    }, [open]);


    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(String(position.coords.latitude));
                    setLongitude(String(position.coords.longitude));
                },
                (error) => {
                    alert('Unable to retrieve your location');
                }
            );
        } else {
            alert('Geolocation is not supported by your browser');
        }
        console.log(dispositionMapping);
    },[]);

    const handleSubmit = async () => {
        try {
            const dispositionValue: InteractionValue[] = [];
            if (ptpAmount) {
                dispositionValue.push({ field_name: "ptp_amount", field_value: ptpAmount });
            }
            if (ptpDate) {
                dispositionValue.push({ field_name: "ptp_date", field_value: ptpDate });
            }
            if (followupDate) {
                dispositionValue.push({ field_name: "followup_date", field_value: followupDate });
            }
            const body = {
                client_id: selectedRow.client_id,
                case_id: selectedRow.case_id,
                disposition: disposition,
                sub_disposition: subDisposition,
                interaction_mode: "Call",
                latitude: latitude,
                longitude: longitude,
                image_url: "",
                comment: comment,
                interaction: dispositionValue,
            }
            const response = await apiClient.post('api/v1/interaction', body);
            if (response.status === 200) {
                alert("Disposition update successful");
            } else {
                alert("Disposition update failed");
            }
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
        }
        onClose();
    };


    return (
        <>
            {addCaseDetailModalOpen && <AddCaseDetailsModal open={addCaseDetailModalOpen} onClose={() => (setAddCaseDetailModalOpen(false))} selectedRow={selectedRow} ></AddCaseDetailsModal>}
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                open={open} onClose={onClose} anchor='right'>


                <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <Typography variant='h6'>{selectedRow.customer_name}</Typography>
                    <IconButton onClick={onClose} aria-label="close drawer" >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <div style={{ display: 'flex' }}>
                    <div style={{width:'500px'}}>


                        <Box sx={{ padding: 2, overflowY: 'auto' }}>
                            <Box className={classes.phoneContainer}>
                                <PhoneIcon />
                                <Typography>{selectedRow.customer_number}</Typography>
                                <Button variant="outlined" onClick={() => setAddCaseDetailModalOpen(true)}>
                                    Add More Details
                                </Button>
                            </Box>
                            <CallCustomerSection openSection={openCallingSection} caseDetailData={caseDetailData} selectedRow={selectedRow} handleCloseSection={() => setOpenCallingSection(!openCallingSection)} />
                            <Divider />
                            <FieldItem fieldName="Client" value={selectedRow.client_name} />
                            <FieldItem fieldName="DPD Bucket" value={selectedRow.dpd_bucket} />
                            <FieldItem fieldName="Loan Type" value={selectedRow.loan_type} />
                            <FieldItem fieldName="Loan Amount" value={selectedRow.collection_amount} />
                            <FieldItem fieldName="Recovered Amount" value={selectedRow.collected_amount} />
                            <FieldItem
                                fieldName="Balance Amount"
                                value={selectedRow.collection_amount - selectedRow.collected_amount}
                            />
                            <Divider />
                            {caseDetailData?.length !== 0 &&
                                <CollapsibleComponent
                                    headerText="Pinned Location"
                                    content={
                                        <Box>
                                            <Box>
                                                <Box >
                                                    <FieldItem
                                                        fieldName={"Latitude"}
                                                        value={customer_latitude}
                                                    />
                                                </Box>
                                                <Box >
                                                    <FieldItem
                                                        fieldName={"Longitude"}
                                                        value={customer_longitude}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    }
                                // headerStyle={{ backgroundColor: DISPOSITION_COLOR_MAP[interactionData[0].disposition] }}
                                />}
                            {caseDetailData?.length !== 0 &&
                                <CollapsibleComponent
                                    headerText="Additional Data"
                                    content={
                                        <Box>
                                            {(caseDetailData?.length !== 0) ? (
                                                <Box>
                                                    {caseDetailData?.length !== 0 &&
                                                        caseDetailData.map((item: any, index: any) => (
                                                            <Box key={index}>
                                                                <FieldItem
                                                                    fieldName={item.field_name}
                                                                    value={item.field_value}
                                                                />
                                                            </Box>
                                                        ))
                                                    }
                                                </Box>
                                            ) : null}
                                        </Box>
                                    }
                                // headerStyle={{ backgroundColor: DISPOSITION_COLOR_MAP[interactionData[0].disposition] }}
                                />}

                            {interactionData?.length !== 0 &&
                                <CollapsibleComponent
                                    headerText={` Latest Disposition: ${interactionData[0].disposition}`}
                                    content={
                                        <Box>
                                            {(interactionData?.length !== 0 && interactionData[0].disposition_value?.length !== 0) ? (
                                                <Box>
                                                    {interactionData[0].disposition_value && (
                                                        JSON.parse(interactionData[0].disposition_value).map((item: any, index: any) => (
                                                            <Box key={index}>
                                                                <FieldItem
                                                                    fieldName={item.field_name}
                                                                    value={item.field_value}
                                                                />
                                                            </Box>
                                                        ))
                                                    )}
                                                </Box>
                                            ) : null}
                                        </Box>
                                    }
                                    headerStyle={{ backgroundColor: DISPOSITION_COLOR_MAP[interactionData[0].disposition] }}
                                />}
                            <Divider sx={{ marginY: "16px" }} />
                        </Box>

                    </div>

                    <div style={{width:'500px'}}>
                    <Tabs value={0} sx={{ marginTop: "4px" }}>
                                <Tab label="Add Disposition" style={{ minWidth: "100%", fontWeight: "bolder", fontSize: "18px" }} />
                            </Tabs>
                            <DropDown label="Select Disposition"
                                items={dispositionMapping?.map((dispositionItem: Disposition) => dispositionItem.disposition_label)}
                                selectedValue={disposition}
                                onSelection={(value) => setDisposition(value)} />
                            {disposition &&
                                <DropDown label="Select Sub Disposition"
                                    items={
                                        dispositionMapping
                                            .find((dispositionItem: Disposition) => dispositionItem.disposition_label === disposition)
                                            ?.sub_disposition.map((subDispositionItem: SubDisposition) => subDispositionItem.sub_disposition_label)
                                    }
                                    selectedValue={subDisposition}
                                    onSelection={(value) => setSubDisposition(value)} />
                            }
                            {
                                subDisposition &&
                                dispositionMapping
                                    .find((dispositionItem: Disposition) => dispositionItem.disposition_label === disposition)
                                    ?.sub_disposition.find((subDispositionItem: SubDisposition) => subDispositionItem.sub_disposition_label === subDisposition)
                                    ?.validation_type.includes("PTP") &&
                                <Box mt={2} display="flex" justifyContent="space-between">
                                    <Box width="48%">
                                        <TextField
                                            margin='normal'
                                            label="PTP Amount"
                                            type="number"
                                            value={ptpAmount}
                                            onChange={(event) => setPtpAmount(event.target.value)}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box width="48%">
                                        <FormControl fullWidth margin="normal">
                                            <InputLabel shrink htmlFor="ptp-date"
                                                sx={{ top: '-8px' }}
                                            >PTP Date</InputLabel>
                                            <TextField
                                                // id='ptp-date'
                                                type="date"
                                                value={ptpDate}
                                                onChange={(event) => setPtpDate(event.target.value)}
                                                fullWidth
                                            />
                                        </FormControl>
                                    </Box>
                                </Box>
                            }
                            {
                                subDisposition &&
                                dispositionMapping
                                    .find((dispositionItem: Disposition) => dispositionItem.disposition_label === disposition)
                                    ?.sub_disposition.find((subDispositionItem: SubDisposition) => subDispositionItem.sub_disposition_label === subDisposition)
                                    ?.validation_type.includes("FOLLOW_UP") &&
                                <Box>
                                    {
                                        dispositionMapping
                                        .find((dispositionItem: Disposition) => dispositionItem.disposition_label === disposition)
                                        ?.sub_disposition.find((subDispositionItem: SubDisposition) => subDispositionItem.sub_disposition === "PartialPaymentReceived")&&
                                        
                                        <Box >
                                        <TextField
                                            margin='normal'
                                            label="Partial Payment Amount"
                                            type="number"
                                            value={ptpAmount}
                                            onChange={(event) => setPtpAmount(event.target.value)}
                                            fullWidth
                                            />
                                    </Box>
                                        }
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel shrink htmlFor="ptp-date"
                                            sx={{ top: '-8px' }}
                                        >Follow Up Date</InputLabel>
                                        <TextField
                                            // id='ptp-date'
                                            type="date"
                                            value={followupDate}
                                            onChange={(event) => setFollowupDate(event.target.value)}
                                            fullWidth
                                        />
                                    </FormControl>
                                </Box>
                            }
                            <Box>
                                <TextField
                                    margin='normal'
                                    type="text"
                                    label="Comment"
                                    value={comment}
                                    onChange={(event) => setComment(event?.target.value)}
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                            </Box>
                            <Box mt={3} mb={3} textAlign="center" >
                                <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>Submit</Button>
                            </Box>
                            <Divider sx={{mb:3}}/>
                            {interactionData?.length !== 0 &&
                                <CollapsibleComponent
                                    collapsed={false}
                                    headerText={`Interaction History`}
                                    content={
                                        <Box sx={{ maxHeight: '40vh', overflowY: 'auto' }}>
                                            {(interactionData?.length !== 0 && interactionData.map((item: any, key: any) => (
                                                <Box key={key} sx={{ p: 2, border: '1px dashed grey' }}>
                                                    <Box key={key}>
                                                        <FieldItem
                                                            fieldName={"Interaction Mode"}
                                                            value={item.interaction_mode}
                                                        />
                                                    </Box>
                                                    <Box key={key}>
                                                        <FieldItem
                                                            fieldName={"agent"}
                                                            value={item.created_by}
                                                        />
                                                    </Box>
                                                    <Box key={key}>
                                                        <FieldItem
                                                            fieldName={"comment"}
                                                            value={item.comment}
                                                        />
                                                    </Box>
                                                    <Box key={key}>
                                                        <FieldItem
                                                            fieldName={"Disposition"}
                                                            value={item.disposition}
                                                        />
                                                    </Box>
                                                    <Box key={key}>
                                                        <FieldItem
                                                            fieldName={"Sub-Disposition"}
                                                            value={item.sub_disposition}
                                                        />
                                                    </Box>
                                                    <Box key={key}>
                                                        <FieldItem
                                                            fieldName={"Date"}
                                                            value={item.created_date.slice(0, 10)}
                                                        />
                                                    </Box>

                                                </Box>
                                            )))}
                                        </Box>
                                    }
                                // headerStyle={{ backgroundColor: DISPOSITION_COLOR_MAP[interactionData[0].disposition] }}
                                />}
                        
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default SeeDetailsDrawer;
