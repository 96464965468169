import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/login/login';
import ErrorPage from './components/errorPage/errorPage';
import Dashboard from './components/dashboard/dashboard';
import HomePage from './components/homePage/homePage';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/homepage" element={<HomePage />} />
        <Route path="/error" element={<ErrorPage />} />
        
      </Routes>
    </Router>
  );
};

export default App;
