import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, IconButton, TextField, FormControl, InputLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchResultGrid from '../searchResultGrid/searchResultGrid';
import modalStyle from '../../styles/modalStyle';
import caseColumnDef from '../../colDef/caseColumnDef';
import apiClient from '../../axiosClient';
import Case from '../../interfaces/case';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import DropDown from '../baseComponents/dropdown';
import Disposition from '../../interfaces/disposition';
import SubDisposition from '../../interfaces/sub_disposition';

interface DispositionUpdateModalProps {
    open: boolean;
    onClose: () => void;
    selectedRow: Case;
}

interface InteractionValue {
    field_name: string,
    field_value: string
}

const DispositionUpdateModal: React.FC<DispositionUpdateModalProps> = ({ open, onClose, selectedRow }) => {
    const [disposition, setDisposition] = useState('');
    const [subDisposition, setSubDisposition] = useState('');
    const [comment, setComment] = useState('');
    const [latitude, setLatitude] = useState<string | null>(null);
    const [longitude, setLongitude] = useState<string | null>(null);
    const [ptpAmount, setPtpAmount] = useState<string | null>(null);
    const [ptpDate, setPtpDate] = useState<string | null>(null);
    const [followupDate, setFollowupDate] = useState<string | null>(null);

    const dispositionMapping = useSelector((state: RootState) => state.dispositionMapping.dispositions);

    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(String(position.coords.latitude));
                    setLongitude(String(position.coords.longitude));
                },
                (error) => {
                    alert('Unable to retrieve your location');
                }
            );
        } else {
            alert('Geolocation is not supported by your browser');
        }
    });

    const handleSubmit = async () => {
        try {
            const dispositionValue: InteractionValue[] = [];
            if (ptpAmount) {
                dispositionValue.push({ field_name: "ptp_amount", field_value: ptpAmount });
            }
            if (ptpDate) {
                dispositionValue.push({ field_name: "ptp_date", field_value: ptpDate });
            }
            if (followupDate) {
                dispositionValue.push({ field_name: "followup_date", field_value: followupDate });
            }
            const body = {
                client_id: selectedRow.client_id,
                case_id: selectedRow.case_id,
                disposition: disposition,
                sub_disposition: subDisposition,
                interaction_mode: "Call",
                latitude: latitude,
                longitude: longitude,
                image_url: "",
                comment: comment,
                interaction: dispositionValue,
            }
            const response = await apiClient.post('api/v1/interaction', body);
            if (response.status === 200) {
                alert("Disposition update successful");
            } else {
                alert("Disposition update failed");
            }
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
        }
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography id="modal-title" variant="h6">
                        Disposition Update
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DropDown label="Select Disposition"
                    items={dispositionMapping?.map((dispositionItem: Disposition) => dispositionItem.disposition_label)}
                    selectedValue={disposition}
                    onSelection={(value) => setDisposition(value)} />
                {disposition &&
                    <DropDown label="Select Sub Disposition"
                        items={
                            dispositionMapping
                                .find((dispositionItem: Disposition) => dispositionItem.disposition_label === disposition)
                                ?.sub_disposition.map((subDispositionItem: SubDisposition) => subDispositionItem.sub_disposition_label)
                        }
                        selectedValue={subDisposition}
                        onSelection={(value) => setSubDisposition(value)} />
                }
                {
                    subDisposition &&
                    dispositionMapping
                        .find((dispositionItem: Disposition) => dispositionItem.disposition_label === disposition)
                        ?.sub_disposition.find((subDispositionItem: SubDisposition) => subDispositionItem.sub_disposition_label === subDisposition)
                        ?.validation_type.includes("PTP") &&
                    <Box mt={2} display="flex" justifyContent="space-between">
                        <Box width="48%">
                            <TextField
                                margin='normal'
                                label="PTP Amount"
                                type="number"
                                value={ptpAmount}
                                onChange={(event) => setPtpAmount(event.target.value)}
                                fullWidth
                            />
                        </Box>
                        <Box width="48%">
                            <FormControl fullWidth margin="normal">
                                <InputLabel shrink htmlFor="ptp-date"
                                    sx={{ top: '-8px' }}
                                >PTP Date</InputLabel>
                                <TextField
                                    // id='ptp-date'
                                    type="date"
                                    value={ptpDate}
                                    onChange={(event) => setPtpDate(event.target.value)}
                                    fullWidth
                                />
                            </FormControl>
                        </Box>
                    </Box>
                }
                {
                    subDisposition &&
                    dispositionMapping
                        .find((dispositionItem: Disposition) => dispositionItem.disposition_label === disposition)
                        ?.sub_disposition.find((subDispositionItem: SubDisposition) => subDispositionItem.sub_disposition_label === subDisposition)
                        ?.validation_type.includes("FOLLOW_UP") &&
                    <Box>
                        <FormControl fullWidth margin="normal">
                            <InputLabel shrink htmlFor="ptp-date"
                                sx={{ top: '-8px' }}
                            >Follow Up Date</InputLabel>
                            <TextField
                                // id='ptp-date'
                                type="date"
                                value={followupDate}
                                onChange={(event) => setFollowupDate(event.target.value)}
                                fullWidth
                            />
                        </FormControl>
                    </Box>
                }
                <Box>
                    <TextField
                        margin='normal'
                        type="text"
                        label="Comment"
                        value={comment}
                        onChange={(event) => setComment(event?.target.value)}
                        fullWidth
                    />
                </Box>
                <Box mt={3}>
                    <Typography variant="subtitle1">Selected Rows:</Typography>
                    <SearchResultGrid
                        height={200}
                        getRowId={(row) => row.case_id}
                        results={[selectedRow]}
                        onSelectRow={() => { }}
                        columns={caseColumnDef}
                    />
                </Box>
                <Box mt={3} textAlign="right">
                    <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default DispositionUpdateModal;
