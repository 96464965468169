
export const SUBDISPOSITION_OPTIONS: { [key: string]: string[] } = {
    under_discussion: ['Under Discussion'],
    promise_to_pay: ['Partial PTP', 'Full PTP'],
    payment_received: ['Full Payment Received', 'Partial Payment Received'],
    not_reachable: ['RNR', 'Switched Off', 'Out of Coverage', 'Wrong Number'],
    follow_up_later: ['Ongoing Medical Issue', 'Slow Business', 'Travelling Currently'],
    disputed_debt: ['Dispute Reported', 'Dispute Validated'],
    refused_to_pay: ['Already Paid', 'Denying Due Amount', 'Wilful Defaulter'],
    referred_to_field: ['Partial PTP', 'Full PTP', 'Full Payment Received', 'Partial Payment Received', 
                        'Promise to Pay', 'Under Discussion', 'Un-traceable', 'Ongoing Medical Issue', 'Slow Business', 
                        'Disputed Debt', 'Refused to Pay'],

  };

export const SUBDISPOSITIONOPTIONS_FOR_FOLLOWUP_DATE  = new Set<string>(['rnr',
    'slow business',
    'travelling currently',
    'under discussion',
    'un-traceable']);

export const SUBDISPOSITIONOPTIONS_FOR_P2P_DATE  = new Set<string>(['rnr',
    'partial ptp', 'full ptp']);
    
export const DISPOSITION_OPTIONS = [
    { label: 'Under Discussion', value: 'under_discussion', color: '#FFF9C4' },
    { label: 'PROMISE_TO_PAY', value: 'promise_to_pay', color: '#C8E6C9' },
    { label: 'Payment Received', value: 'payment_received', color: '#4CAF50' },
    { label: 'Non Reachable', value: 'not_reachable', color: '#B0BEC5' },
    { label: 'Follow up Later', value: 'follow_up_later', color: '#B0BEC5' },
    { label: 'Disputed Debt', value: 'disputed_debt', color: '#F44336' },
    { label: 'Refused to Pay', value: 'refused_to_pay', color: '#F44336' },
    { label: 'Referred to field', value: 'referred_to_field', color: '#FFF9C4' },
  ];

  interface DispositionColorMap {
    [key: string]: string;
}
// Create a map with dispositionOption as the key and color as the value
export const DISPOSITION_COLOR_MAP: DispositionColorMap = DISPOSITION_OPTIONS.reduce((map, option) => {
    map[option.label] = option.color;
    return map;
}, {} as DispositionColorMap);

  export const DPD_BUCKET_OPTIONS = [
    { id: 'DPD 31-60', name: 'DPD 31-60' },
    { id: 'DPD 61-90', name: 'DPD 61-90' },
    { id: 'DPD 91-120', name: 'DPD 91-120' },
    { id: 'DPD 121-150', name: 'DPD 121-150' },
    { id: 'DPD 151-180', name: 'DPD 151-180' },
    { id: 'DPD 181-270', name: 'DPD 181-270' },
    { id: 'DPD 271-360', name: 'DPD 271-360' },
    { id: 'DPD 360+', name: 'DPD 360+' },
  ];
  