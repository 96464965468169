import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface DropDownProps {
    label: string,
    selectedValue: any,
    onSelection: (value: any) => void,
    items: any,
    keyProp?: string,
    valueProp?: string,
    displayProp?: string,
    multiple?: boolean,
    disabled?: boolean
}

const DropDown: React.FC<DropDownProps> = ({ label, selectedValue, onSelection, items, keyProp, valueProp, displayProp, multiple, disabled }) => {

    return (
        <Box width="100%">
            <FormControl fullWidth margin="normal" disabled={disabled}>
                <InputLabel sx={{ top: '-8px' }}>{label}</InputLabel>
                <Select
                    multiple={multiple ? true : false}
                    value={selectedValue}
                    onChange={(event) => onSelection(event.target.value)}
                >
                    {items?.map((item: any) => (
                        <MenuItem key={keyProp ? item[keyProp] : item} value={valueProp ? item[valueProp] : item}>{displayProp ? item[displayProp] : item}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default DropDown;