import { Box, Button, IconButton, Modal, TextField, Typography } from "@mui/material"
import { useState } from "react";
import modalStyle from "../../styles/modalStyle";
import CloseIcon from '@mui/icons-material/Close';
import apiClient from "../../axiosClient";
import DropDown from "../baseComponents/dropdown";
import Picklist from "../../interfaces/picklist";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";


interface ModalProps {
    openModal: boolean,
    handleCloseModal: () => void
}

interface Agent {
    name: string,
    password: string,
    email: string,
    phone_number: string,
    user_role: string,
    address: string
}

const AddAgentModal: React.FC<ModalProps> = ({ openModal, handleCloseModal }) => {

    const [agent, setAgent] = useState<Agent>({
        name: "",
        email: "",
        phone_number: "",
        address: "",
        user_role: "",
        password: ""
    });


    const picklists: Picklist = useSelector((state: RootState) => state.picklists.picklists);

    const handleSetAgent = <K extends keyof Agent> (key: K, value:Agent[K]) => {
        setAgent((prevAgent) => ({ ...prevAgent, [key]: value }));
    }

    // Handle form submission
    const handleSubmit = async () => {
        try {

            if (!agent.name || !agent.address || !agent.phone_number || !agent.password || !agent.user_role || !agent.email) {
                alert('Please fill all required values');
                return;
            }
            // Make POST request to add new client (use your real API endpoint)
            const response = await apiClient.post('/api/v1/internal_user', { ...agent });
            alert("Successfully Added User");
            handleCloseModal(); // Close the modal on success
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
        }
    };


    return (
        <Modal open={openModal} onClose={handleCloseModal} >
            <Box sx={modalStyle}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography id="modal-title" variant="h6">
                        Add New Internal User
                    </Typography>
                    <IconButton onClick={handleCloseModal}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box mt={2} display="flex" justifyContent="space-between">
                    <Box width="30%">
                        <TextField
                            label="User Name"
                            value={agent.name}
                            onChange={(e) => handleSetAgent('name', e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </Box>
                    <Box width="30%">
                        <DropDown label="User Role" items={picklists.USER_ROLE} selectedValue={agent.user_role} onSelection={(e) => handleSetAgent('user_role', e)} />
                    </Box>
                    <Box width="30%">
                        <TextField
                            label="Password"
                            value={agent.password}
                            type="password"
                            onChange={(e) => handleSetAgent('password', e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </Box>
                </Box>
                <Box mt={2} display="flex" justifyContent="space-between">
                    <Box width="48%">
                        <TextField
                            label="Email"
                            value={agent.email}
                            type="email"
                            onChange={(e) => handleSetAgent('email', e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </Box>
                    <Box width="48%">
                        <TextField
                            label="Phone Number"
                            value={agent.phone_number}
                            type="tel"
                            onChange={(e) => handleSetAgent('phone_number', e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </Box>
                </Box>
                <Box mt={2} display="flex" justifyContent="space-between">
                    <Box width="100%">
                        <TextField
                            label="Address"
                            value={agent.address}
                            type="text"
                            onChange={(e) => handleSetAgent('address', e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </Box>
                </Box>
                <Box mt={2} textAlign="right">
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Box>
            </Box>
        </Modal >
    );
}

export default AddAgentModal;