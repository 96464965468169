import React, { useState } from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import Case from '../../interfaces/case';
import SearchResultGrid from '../searchResultGrid/searchResultGrid';
import CloseIcon from '@mui/icons-material/Close';
import modalStyle from '../../styles/modalStyle';
import caseColumnDef from '../../colDef/caseColumnDef';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import apiClient from '../../axiosClient';
import InternalUser from '../../interfaces/internal_user';
import DropDown from '../baseComponents/dropdown';

interface ReassignmentModalProps {
    open: boolean;
    onClose: () => void;
    selectedRows: Case[];
}

const ReassignmentModal: React.FC<ReassignmentModalProps> = ({ open, onClose, selectedRows }) => {
    const [selectedTerriory, setSelectedTerritory] = useState<string>();
    const [territoryUsers, setTerritoryUsers] = useState<InternalUser[]>([]);
    const [selectedUser, setSelectedUser] = useState<string>();

    const territories = useSelector((state: RootState) => state.territories.territories);

    const handleTerritoryChange = (territoryId: string) => {
        setSelectedTerritory(territoryId);
        fetchTerritoryUsers(territoryId); // Reset the second dropdown when the first changes
    };

    const fetchTerritoryUsers = async (territory_id: string) => {
        try {
            const response = await apiClient.get('/api/v1/internal_user?territory_id=' + territory_id);
            if (response.status === 200) {
                setTerritoryUsers(response.data as InternalUser[]);
            } else {
                alert("Error while loading territory user list");
            }
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
        }
    };

    const handleSubmit = async () => {
        try {
            const assignments = selectedRows.map((row: Case) => {
                const user: InternalUser|undefined = territoryUsers.find((user: InternalUser) => user.user_id === selectedUser);
                return {
                    "client_id": row.client_id,
                    "case_id": row.case_id,
                    "assigned_to": user?.username,
                    "assigned_at": user?.user_role,
                    "assigned_to_old": !row.assigned_to ? 'null' : row.assigned_to,
                    "assigned_at_old": !row.assigned_at ? 'null' : row.assigned_at
                }
            });
            const response = await apiClient.put('api/v1/case/assignment', assignments);
            if (response.status === 200) {
                alert("Assignment update successful");
            } else {
                alert("Assignment update failed");
            }
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
        }
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography id="modal-title" variant="h6">
                        Reassignment
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <DropDown label="Select Territory" items={territories} selectedValue={selectedTerriory} 
                onSelection={(value) => handleTerritoryChange(value)} keyProp="territory_id" valueProp="territory_id" displayProp="name" />

                <DropDown label="Select User" items={territoryUsers} selectedValue={selectedUser} 
                onSelection={(value) => setSelectedUser(value)} keyProp="user_id" valueProp="user_id" displayProp="username" />
                
                <Box mt={3}>
                    <Typography variant="subtitle1">Selected Rows:</Typography>
                    <SearchResultGrid
                        getRowId={(row) => row.case_id}
                        results={selectedRows}
                        onSelectRow={() => { }}
                        columns={caseColumnDef}
                    />
                </Box>
                <Box mt={3} textAlign="right">
                    <Button variant="contained" disabled={!selectedUser} color="primary" onClick={handleSubmit}>Submit</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ReassignmentModal;
