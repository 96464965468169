import { configureStore } from '@reduxjs/toolkit';
import clientReducer from './slices/clientSlice';
import territoryReducer from './slices/territorySlice';
import fieldReducer from './slices/fieldSlice';
import picklistReducer from './slices/picklistSlice';
import dispositionMappingReducer from './slices/dispositionMappingSlice';

const store = configureStore({
    reducer: {
        clients: clientReducer,
        territories: territoryReducer,
        fields: fieldReducer,
        picklists: picklistReducer,
        dispositionMapping: dispositionMappingReducer
    },
})

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
