import React, { useEffect, useState } from 'react';
import { Box, Drawer, AppBar, Toolbar, IconButton, Typography, Button } from '@mui/material';
import SearchResultGrid from '../searchResultGrid/searchResultGrid';
import FilterSection from '../filterSection/filterSection';
import UploadCaseFile from '../uploadCaseFile/uploadCaseFile';
import GridActions from '../gridActions/gridActions';
import apiClient from '../../axiosClient';
import Case from '../../interfaces/case';
import caseColumnDef from '../../colDef/caseColumnDef';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import Field from '../../interfaces/field';
import filterSectionStyle from '../../styles/filterSectionStyle';
import Chips from '../baseComponents/chips';
import Disposition from '../../interfaces/disposition';
import ChipItem from '../baseComponents/chipItem';
import RoleManagement from '../../security/roleManagement';
import UploadComponent from '../baseComponents/uploadComponent';
import FilterListIcon from '@mui/icons-material/FilterAltOutlined';
import CloseIcon from '@mui/icons-material/Close';
import FilterDrawer from './filterDrawer';

const drawerWidth: number = 250;

const Dashboard: React.FC = () => {
    const [searchResults, setSearchResults] = useState<Case[]>([]);
    const [filterResults, setFilterResults] = useState<Case[]>([]);
    const [selectedRows, setSelectedRows] = useState<Case[]>([]);
    const [filterKeys, setFilterKeys] = useState<string[]>([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [leftMarginDashboard, setLeftMarginDashboard] = useState<string>('16px');


    const fields = useSelector((state: RootState) => state.fields.fields);
    const dispositionMapping = useSelector((state: RootState) => state.dispositionMapping.dispositions);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
        if (!drawerOpen)
            setLeftMarginDashboard('166px');
        else
            setLeftMarginDashboard('16px');
    };

    const createQueryParam = (filters: { key: string; value: string }[]) => {
        let query_param = "";
        for (const filter of filters) {
            if (filter.key && filter.key !== '' && filter.value !== '') {
                query_param = query_param + filter.key + "=" + filter.value + "&";
            }
        }
        return query_param;
    }

    useEffect(() => {
        // Make API call to fetch dropdown keys on component load
        const fetchKeys = async () => {
            try {
                let keys: string[] = fields.filter((field: Field) => field.field_type === 'PRIMARY').map((field: Field) => field.field_name);
                keys = [...keys, ...['client_id', 'status', 'assigned_to', 'assigned_at', 'territory_id']];
                setFilterKeys(keys);
            } catch (error) {
                console.error('Error fetching keys:', error);
            }
        };

        fetchKeys();
    }, [fields]);

    function extractInteractionValue(interactionValue: any, field: string, defaultField: string) {
        if (interactionValue) {
            const fieldValue = interactionValue.find((value: any) => value.field_name === field);
            if (fieldValue) {
                return fieldValue.field_value;
            }

            const defaultFieldValue = interactionValue.find((value: any) => value.field_name === defaultField);
            if (defaultFieldValue) {
                return defaultFieldValue.field_value;
            }
        }
        return '';
    }

    const createDisplayRows = (responses: any) => {
        const records = [];
        for (const response of responses) {
            const record: Case = {
                case_id: response.case.case_id,
                loan_type: response.case.loan_type,
                client_id: response.case.client_id,
                client_name: response.client.client_name,
                territory_name: response.territory.name,
                dpd_bucket: response.case.dpd_bucket,
                customer_name: response.case.customer_name,
                customer_number: response.case.customer_phone_number,
                collection_amount: response.case.collection_amount,
                collected_amount: response.case.collected_amount,
                settlement_amount: response.case.eligible_settlement_amount,
                status: response.case.status,
                interaction: response.interactions[0]?.disposition,
                sub_interaction: response.interactions[0]?.sub_disposition,
                ptp_data_or_followup_date: extractInteractionValue(response.interactions[0]?.disposition_value, 'ptp_date', 'followup_date'),
                ptp_amount: extractInteractionValue(response.interactions[0]?.disposition_value, 'ptp_amount', ''),
                comment: response.interactions[0]?.comment,
                interaction_date: response.interactions[0]?.updated_date,
                assigned_to: response.case.assigned_to,
                assigned_at: response.case.assigned_at,
                pin_code: response.case.pin_code,
                created_date: response.case.created_date,
                updated_date: response.case.updated_date,
                updated_by: response.case.updated_by
            }
            records.push(record);
        }
        return records
    }

    const handleSearch = async (filters: { key: string; value: string }[]) => {
        const query_param = createQueryParam(filters);
        try {
            const response = await apiClient.get('/api/v1/case?' + query_param);
            if (response.status === 200) {
                const records = createDisplayRows(response.data);
                setSearchResults(records);
                setFilterResults(records);
            } else {
                alert("Could not search cases: " + response.data.message);
            }
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
        }
    };

    const handleReset = () => {
        setSearchResults([]);
        setFilterResults([]);
    };

    const handleSelectRow = (ids: string[]) => {
        setSelectedRows(searchResults.filter((row) => ids.includes(row.case_id)));
    };

    const handleDispositionFilter = (item: ChipItem | null) => {
        setFilterResults(item ? searchResults.filter((row: Case) => row.interaction === item.label) : searchResults);
    }

    return (
        <Box sx={{ ml: leftMarginDashboard, mr: 2 }}>
            <FilterDrawer drawerWidth={drawerWidth} drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} handleSearch={handleSearch} filterKeys={filterKeys} />
            <Box sx={filterSectionStyle} >
                <Box display="flex" justifyContent={'space-between'}>
                    <Box>
                    <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<FilterListIcon fontSize="large"  />}
                        onClick={toggleDrawer}
                        aria-label="open drawer"
                        >
                        Filters
                    </Button>
                    </Box>
                    <Box display="flex" justifyContent={'flex-end'}>

                        {!RoleManagement.isAgent(localStorage.getItem("role")) && <UploadComponent label="Upload Case File" uploadApi="/api/v1/case/upload" uploadType='CaseUpload' />}
                        {!RoleManagement.isAgent(localStorage.getItem("role")) && <UploadComponent label="Upload Payment File" uploadApi="/api/v1/reconcile/upload" uploadType='PaymentFile' />}
                    </Box>
                </Box>
                {/* <FilterSection onSearch={handleSearch} dropdownKeys={filterKeys} /> */}
                <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                    {searchResults?.length > 0 && (
                        <Chips
                            items={dispositionMapping?.map((disposition: Disposition) => ({
                                id: disposition.disposition,
                                label: disposition.disposition_label,
                            }))}
                            onSelect={handleDispositionFilter}
                        />
                    )}
                </div>
            </Box>
            <GridActions selectedCases={selectedRows} />
            <SearchResultGrid
                height='70vh'
                getRowId={(row) => row.case_id}
                results={filterResults}
                onSelectRow={handleSelectRow}
                columns={caseColumnDef}
                exportFileName='cases'
            />
        </Box>
    );
};

export default Dashboard;