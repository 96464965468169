import { Box, Button } from "@mui/material";
import SearchResultGrid from "../searchResultGrid/searchResultGrid";
import { useEffect, useState } from "react";
import InternalUser from "../../interfaces/internal_user";
import { GridColDef } from "@mui/x-data-grid";
import apiClient from "../../axiosClient";
import AddAgentsToTerritoryModal from "../modals/addAgentsToTerritoryModal";


interface AgentProps {
    selectedTerritory: string | undefined,
    load: boolean
}

// Columns for the DataGrid
const columns: GridColDef[] = [
    { field: 'user_id', headerName: 'User Id', width: 300 },
    { field: 'username', headerName: 'Username', width: 300 },
    { field: 'user_role', headerName: 'User Role', width: 300 },
    { field: 'name', headerName: 'Name', width: 300 },
    { field: 'address', headerName: 'Address', width: 300 },
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'phone_number', headerName: 'Phone Number', width: 200 },
    { field: 'created_date', headerName: 'Created Date', width: 300 },
    { field: 'created_by', headerName: 'Created By', width: 300 }
];


const ManageAgents: React.FC<AgentProps> = ({ selectedTerritory, load }) => {
    const [internalUser, setInternalUser] = useState<InternalUser[]>([]);

    useEffect(() => {
        const handleFetchInternalUsers = async () => {
            try {
                const response = await apiClient.get(`/api/v1/internal_user?territory_id=` + selectedTerritory);
                setInternalUser(response.data as InternalUser[]);
            } catch (error: any) {
                if (error.response) {
                    alert('Error response data:' + error.response.data.message);
                } else if (error.request) {
                    alert('No response received:' + error.request);
                } else {
                    alert('Error message:' + error.message);
                }
            }
        };
        handleFetchInternalUsers();
    }, [selectedTerritory, load])

    return (
        <Box>
            <SearchResultGrid
                getRowId={(row: InternalUser) => row.user_id}
                results={internalUser}
                onSelectRow={() => {}}
                columns={columns}
                exportFileName='agents'
            />
        </Box>
    );
}

export default ManageAgents;