import axios from 'axios';

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

// Add a request interceptor to inject the token
apiClient.interceptors.request.use(
    (config) => {
        if (!config.url?.includes("login")) {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            } else {
                // Redirect to login if token is undefined
                window.location.href = '/login'; // Adjust the path as necessary
                // You can also return a rejected promise to prevent the request from proceeding
                return Promise.reject(new Error('No token found'));
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default apiClient;
