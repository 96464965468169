import { Box, Tab, Tabs, List, Drawer, ListItem, ListItemText, ListItemButton, ListItemIcon, Tooltip,IconButton  } from "@mui/material";
import Header from "../header/header";
import { useEffect, useState } from "react";
import Dashboard from "../dashboard/dashboard";
import { loadClients } from "../../store/slices/clientSlice";
import apiClient from "../../axiosClient";
import Client from "../../interfaces/client";
import { loadTerritories } from "../../store/slices/territorySlice";
import Territory from "../../interfaces/territory";
import { AppDispatch } from "../../store/store";
import { useAppDispatch } from "../../store/hooks";
import { loadFields } from "../../store/slices/fieldSlice";
import Field from "../../interfaces/field";
import ManageClient from "../dashboard/manageClient";
import ManageTerritory from "../dashboard/manageTerritory";
import ManageField from "../dashboard/manageField";
import ManageSettlementRule from "../dashboard/manageSettlementRule";
import { loadPicklist } from "../../store/slices/picklistSlice";
import Picklist from "../../interfaces/picklist";
import { loadDispositionMapping } from "../../store/slices/dispositionMappingSlice";
import Disposition from "../../interfaces/disposition";
import RoleManagement from "../../security/roleManagement";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageClientIcon from '@mui/icons-material/PeopleAlt';
import ManageTerritoryIcon from '@mui/icons-material/Place';
import ManageFieldIcon from '@mui/icons-material/Storage';
import ManageSettlementRulesIcon from '@mui/icons-material/Gavel';
import LogoutIcon from '@mui/icons-material/Logout';
import './homepage.css';
import { useNavigate } from "react-router-dom";

const HomePage: React.FC = () => {
    const navigate = useNavigate();
  

    const [selectedTab, setSelectedTab] = useState<number>(0);


    const dispatch: AppDispatch = useAppDispatch();
    const drawerWidth = 240;

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const handleLogout = ()=>{
        localStorage.removeItem("token");
        navigate('/login');
    }

    useEffect(() => {
        // Make API call to fetch dropdown keys on component load
        const fetchClients = async () => {
            try {
                const response = await apiClient.get('/api/v1/client');
                if (response.status === 200) {
                    dispatch(loadClients(response.data as Client[]));
                } else {
                    alert("Error while loading client list. Error: " + response.data.message);
                }
            } catch (error: any) {
                if (error.response) {
                    alert('Error response data:' + error.response.data.message);
                } else if (error.request) {
                    alert('No response received:' + error.request);
                } else {
                    alert('Error message:' + error.message);
                }
            }
        };

        const fetchTerritories = async () => {
            try {
                const response = await apiClient.get('/api/v1/territory');
                if (response.status === 200) {
                    dispatch(loadTerritories(response.data as Territory[]));
                } else {
                    alert("Error while loading territory list. Error: " + response.data.message);
                }
            } catch (error: any) {
                if (error.response) {
                    alert('Error response data:' + error.response.data.message);
                } else if (error.request) {
                    alert('No response received:' + error.request);
                } else {
                    alert('Error message:' + error.message);
                }
            }
        }

        const fetchFields = async () => {
            try {
                const response = await apiClient.get('/api/v1/field');
                if (response.status === 200) {
                    dispatch(loadFields(response.data as Field[]));
                } else {
                    alert("Error while loading field list. Error: " + response.data.message);
                }
            } catch (error: any) {
                if (error.response) {
                    alert('Error response data:' + error.response.data.message);
                } else if (error.request) {
                    alert('No response received:' + error.request);
                } else {
                    alert('Error message:' + error.message);
                }
            }
        };

        const fetchPicklist = async () => {
            try {
                const response = await apiClient.get('/api/v1/picklist');
                if (response.status === 200) {
                    dispatch(loadPicklist(response.data as Picklist));
                } else {
                    alert("Error while loading picklist. Error: " + response.data.message);
                }
            } catch (error: any) {
                if (error.response) {
                    alert('Error response data:' + error.response.data.message);
                } else if (error.request) {
                    alert('No response received:' + error.request);
                } else {
                    alert('Error message:' + error.message);
                }
            }
        };

        const fetchDispositionTypes = async () => {
            try {
                const response = await apiClient.get("/api/v1/disposition");
                if (response.status === 200) {
                    dispatch(loadDispositionMapping(response.data as Disposition[]));
                } else {
                    alert("Error while loading disposition mapping. Error:" + response.data.message);
                }
            } catch (error: any) {
                if (error.response) {
                    alert('Error response data:' + error.response.data.message);
                } else if (error.request) {
                    alert('No response received:' + error.request);
                } else {
                    alert('Error message:' + error.message);
                }
            }
        }

        fetchClients();
        fetchTerritories();
        fetchFields();
        fetchPicklist();
        fetchDispositionTypes();
    }, []);


    return (
        <Box m={2} sx={{ height: '100vh', width: '100vw', margin:0}}>

            <Header />
            <div style={{ display: "flex", flexDirection: "row", height: 'calc(100vh - 84px)' }}>
                <div className="design-system-editor-left-nav" >
                    <List>
                        <ListItemButton style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRight: selectedTab === 0 ? '5px solid #2c3e50' : 'none',
                        }} onClick={() => setSelectedTab(0)}>
                            <ListItemIcon style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText style={{
                                textAlign: 'center'
                            }} secondary="Dashboard" />
                        </ListItemButton>

                        {!RoleManagement.isAgent(localStorage.getItem("role")) && (
                            <ListItemButton style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRight: selectedTab === 1 ? '5px solid #2c3e50' : 'none',
                            }} onClick={() => setSelectedTab(1)}>
                                <ListItemIcon style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                    <ManageClientIcon />
                                </ListItemIcon>
                                <ListItemText style={{
                                    textAlign: 'center'
                                }} secondary="Manage Client" />
                            </ListItemButton>
                        )}
                        {!RoleManagement.isAgent(localStorage.getItem("role")) && (
                            <ListItemButton style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRight: selectedTab === 2 ? '5px solid #2c3e50' : 'none',
                            }} onClick={() => setSelectedTab(2)}>
                                <ListItemIcon style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                    <ManageTerritoryIcon />
                                </ListItemIcon>
                                <ListItemText style={{
                                    textAlign: 'center'
                                }} secondary="Manage Territory" />
                            </ListItemButton>
                        )}
                        {!RoleManagement.isAgent(localStorage.getItem("role")) && (
                            <ListItemButton style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRight: selectedTab === 3 ? '5px solid #2c3e50' : 'none',
                            }} onClick={() => setSelectedTab(3)}>
                                <ListItemIcon style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                    <ManageFieldIcon />
                                </ListItemIcon>
                                <ListItemText style={{
                                    textAlign: 'center'
                                }} secondary="Manage Field" />
                            </ListItemButton>
                        )}
                        {!RoleManagement.isAgent(localStorage.getItem("role")) && (
                            <ListItemButton style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRight: selectedTab === 4 ? '5px solid #2c3e50' : 'none',
                            }} onClick={() => setSelectedTab(4)}>
                                <ListItemIcon style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                    <ManageSettlementRulesIcon />
                                </ListItemIcon>
                                <ListItemText style={{
                                    textAlign: 'center'
                                }} secondary="Manage Settlement Rules" />
                            </ListItemButton>
                        )}
                    </List>

                    
                    <Tooltip title="Logout">
                        <IconButton color="error" onClick={handleLogout} style={{minHeight:'125px'}}>
                            <LogoutIcon fontSize="large"/>
                        </IconButton>
                    </Tooltip>
                </div>
                <div className="design-system-editor-right-content">

                    <div>
                        {selectedTab === 0 && <Dashboard />}
                        {selectedTab === 1 && <ManageClient />}
                        {selectedTab === 2 && <ManageTerritory />}
                        {selectedTab === 3 && <ManageField />}
                        {selectedTab === 4 && <ManageSettlementRule />}
                    </div>
                </div>
            </div>
        </Box>
    );
}

export default HomePage;