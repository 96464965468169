import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    CircularProgress,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import apiClient from '../../axiosClient';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import DropDown from '../baseComponents/dropdown';
import { upload } from '@testing-library/user-event/dist/upload';

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
};

interface UploadProp {
    label: string,
    uploadApi: string,
    uploadType: string
}

const UploadComponent: React.FC<UploadProp> = ({ label, uploadApi, uploadType }) => {
    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isUploading, setIsUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [selectedClient, setSelectedClient] = useState<string | null>(null);

    const clients = useSelector((state: RootState) => state.clients.clients);

    useEffect(() => {
        setSelectedClient(null);
        setSuccessMessage(null);
    }, [])

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    const handleUploadClick = async () => {
        if (!selectedFile) {
            setErrorMessage('Please select a file before uploading.');
            return;
        }

        setIsUploading(true);
        setErrorMessage(null);

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            // Replace with your actual API endpoint
            const response = await apiClient.post(uploadApi + "?client_id=" + selectedClient, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                responseType: uploadType == "PaymentFile" ? 'blob' : 'json'
            });
            if (response.status === 200) {
                if (uploadType == "PaymentFile") {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;

                    link.setAttribute('download', 'data.txt');
                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                }
                setSuccessMessage("file uploaded successfully");
            } else {
                setErrorMessage('File upload failed. Please try again. Error: ' + response.data.message);
            }
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
        } finally {
            setIsUploading(false);
        }
    };

    const handleModalOpen = () => setOpen(true);
    const handleModalClose = () => {
        setOpen(false);
        setSelectedFile(null);
        setErrorMessage(null);
    };

    const handleClientSelection = (client: string) => {
        setSelectedClient(client);
    }

    return (
        <Box display="flex" justifyContent="flex-end" marginY="10px" marginLeft="10px">
            {/* Trigger button to open the modal */}
            <Button
                variant="contained"
                onClick={handleModalOpen}
                sx={{
                    bgcolor: '#1976d2',
                    color: 'white',
                    fontWeight: 'bold',
                    '&:hover': {
                        bgcolor: '#1565c0',
                    },
                }}
            >
                {label}
            </Button>

            {/* Modal for uploading file */}
            <Modal
                open={open}
                onClose={handleModalClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={modalStyle}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography id="modal-title" variant="h6">
                            {label}
                        </Typography>
                        <IconButton onClick={handleModalClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <DropDown label="Select Client" items={clients} selectedValue={selectedClient}
                        onSelection={(value) => handleClientSelection(value)} keyProp="client_id" valueProp="client_id" displayProp="client_name" />

                    <Box mt={2} mb={2}>
                        <Typography variant="body2" color="textSecondary">
                            Select an Excel file (.xlsx)
                        </Typography>
                    </Box>

                    {/* File Input */}
                    <Box mt={1} mb={2}>
                        <TextField
                            type="file"
                            fullWidth
                            inputProps={{ accept: '.xlsx' }}
                            onChange={handleFileChange}
                            sx={{
                                '& input': {
                                    padding: '8px',
                                },
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                            }}
                        />
                    </Box>

                    {/* Display selected file name */}
                    {selectedFile && (
                        <Box mb={2}>
                            <Typography variant="body1" fontWeight="bold">
                                Selected file: {selectedFile.name}
                            </Typography>
                        </Box>
                    )}

                    {/* Error Message */}
                    {errorMessage && (
                        <Box mb={2}>
                            <Typography variant="body2" color="error">
                                {errorMessage}
                            </Typography>
                        </Box>
                    )}

                    {successMessage && (
                        <Box mb={2}>
                            <Typography variant="body2" color="success">
                                {successMessage}
                            </Typography>
                        </Box>
                    )}

                    {/* Upload Button */}
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleUploadClick}
                            disabled={isUploading}
                            sx={{
                                bgcolor: '#1976d2',
                                color: 'white',
                                fontWeight: 'bold',
                                '&:hover': {
                                    bgcolor: '#1565c0',
                                },
                            }}
                        >
                            {isUploading ? <CircularProgress size={24} /> : 'Upload'}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default UploadComponent;
