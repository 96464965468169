import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import Territory from '../../interfaces/territory';
import ManagePincodes from '../managePincodes/managePincodes';
import ManageAgents from '../manageAgents/manageAgents';
import DropDown from '../baseComponents/dropdown';
import filterSectionStyle from '../../styles/filterSectionStyle';
import AddTerritoryModal from '../modals/addTerritoryModal';
import AddAgentModal from '../modals/addAgentModal';
import AddAgentsToTerritoryModal from '../modals/addAgentsToTerritoryModal';


const ManageTerritory = () => {
    const [selectedTerritory, setSelectedTerritory] = useState<string>();
    const [view, setView] = useState<string>();
    const [load, doLoad] = useState<boolean>(true);
    const [openTerritoryModal, setOpenTerritoryModal] = useState(false);
    const [openAgentModal, setOpenAgentModal] = useState(false);
    const [openAgentToTerritoryModal, setOpenAgentToTerritoryModal] = useState(false);

    // Handle modal open/close
    const handleOpenTerritoryModal = () => setOpenTerritoryModal(true);
    const handleCloseTerritoryModal = () => setOpenTerritoryModal(false);

    const handleOpenAgentModal = () => setOpenAgentModal(true);
    const handleCloseAgentModal = () => setOpenAgentModal(false);

    const handleOpenAgentToTerritoryModal = () => setOpenAgentToTerritoryModal(true);
    const handleCloseAgentToTerritoryModal = () => setOpenAgentToTerritoryModal(false);

    const territories: Territory[] = useSelector((state: RootState) => state.territories.territories);

    return (
        <Box m={2}>
            <Box sx={filterSectionStyle}>
                <Box display="flex" justifyContent="flex-end">
                    {/* Trigger button to open the modal */}
                    <Button
                        variant="contained"
                        onClick={handleOpenTerritoryModal}
                        color='info'
                        sx={{
                            marginRight: '10px'
                        }}
                    >
                        Add Territory
                    </Button>

                    <Button
                        variant="contained"
                        onClick={handleOpenAgentModal}
                        color='warning'
                        sx={{
                            marginRight: '10px'
                        }}
                    >
                        Add Agent
                    </Button>

                    <Button
                        variant="contained"
                        onClick={handleOpenAgentToTerritoryModal}
                        color='secondary'
                        sx={{
                            marginRight: '10px'
                        }}
                    >
                        Add Agent To Territory
                    </Button>
                </Box>
                <DropDown label="Select Territory" items={territories} selectedValue={selectedTerritory} onSelection={setSelectedTerritory} keyProp="territory_id" valueProp="territory_id" displayProp="name" />
                <Box display="flex" marginY="10px">
                    <Button
                        variant="contained"
                        onClick={() => {setView('Pincode'); doLoad(!load)}}
                        disabled={!selectedTerritory}
                        style={{ marginRight: 10 }}
                    >
                        Load Pincodes
                    </Button>

                    <Button
                        variant="contained"
                        onClick={() => {setView('Agents'); doLoad(!load)}}
                        disabled={!selectedTerritory}
                    >
                        Load Agents
                    </Button>
                </Box>
            </Box>

            {view === 'Pincode' && <ManagePincodes selectedTerritory={selectedTerritory} load={load} />}
            {view === 'Agents' && <ManageAgents selectedTerritory={selectedTerritory} load={load}/>}

            <AddTerritoryModal openModal={openTerritoryModal} handleCloseModal={handleCloseTerritoryModal}/>
            <AddAgentModal openModal={openAgentModal} handleCloseModal={handleCloseAgentModal}/>
            <AddAgentsToTerritoryModal openModal={openAgentToTerritoryModal} handleCloseModal={handleCloseAgentToTerritoryModal}/>
        </Box>
    );
};

export default ManageTerritory;
