import React, { useState } from 'react';
import { Box, Button, IconButton, Modal, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AppDispatch, RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import Client from '../../interfaces/client';
import { loadClients } from '../../store/slices/clientSlice';
import { useAppDispatch } from '../../store/hooks';
import SearchResultGrid from '../searchResultGrid/searchResultGrid';
import modalStyle from '../../styles/modalStyle';
import apiClient from '../../axiosClient';
import clientColumnDef from '../../colDef/clientColumnDef';

const ManageClient = () => {
    const [openModal, setOpenModal] = useState(false);
    const [clientName, setClientName] = useState<string>();
    const [clientAddress, setClientAddress] = useState<string>();
    const [clientContact, setClientContact] = useState<string>();

    const dispatch: AppDispatch = useAppDispatch();

    let clients: Client[] = useSelector((state: RootState) => state.clients.clients);

    // Handle modal open/close
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setClientName(undefined);
        setClientContact(undefined);
        setClientAddress(undefined);
        setOpenModal(false);
    };

    // Handle form submission
    const handleSubmit = async () => {
        try {

            if (!clientName || !clientContact || !clientAddress) {
                alert('Please fill all required values');
                return;
            }
            // Make POST request to add new client (use your real API endpoint)
            const response = await apiClient.post('/api/v1/client',
                {
                    'client_name': clientName,
                    'client_address': clientAddress,
                    'client_contact': clientContact
                }
            );
            clients = [...clients, response.data as Client];
            dispatch(loadClients(clients));

            handleCloseModal(); // Close the modal on success
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
        }
    };

    return (
        <Box m={2}>
            <Box display="flex" justifyContent="flex-end" marginY="10px">
                <Button variant="contained" onClick={handleOpenModal}>
                    Add New Client
                </Button>
            </Box>

            <SearchResultGrid
                height='80vh'
                getRowId={(row: Client) => row.client_id}
                results={clients}
                onSelectRow={() => { }}
                columns={clientColumnDef}
                exportFileName='clients'
            />

            {/* Modal for adding a new client */}
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={modalStyle}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography id="modal-title" variant="h6">
                        Add New Client
                        </Typography>
                        <IconButton onClick={handleCloseModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <TextField
                        label="Client Name"
                        name="name"
                        value={clientName}
                        onChange={(event) => setClientName(event?.target.value)}
                        fullWidth
                        required
                        margin="normal"
                    />
                    <TextField
                        label="Client Address"
                        name="address"
                        value={clientAddress}
                        onChange={(event) => setClientAddress(event?.target.value)}
                        fullWidth
                        required
                        margin="normal"
                    />
                    <TextField
                        label="Client Contact"
                        name="contact"
                        value={clientContact}
                        onChange={(event) => setClientContact(event?.target.value)}
                        fullWidth
                        required
                        margin="normal"
                    />
                    <Box mt={2} textAlign="right">
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default ManageClient;
