import { GridColDef } from "@mui/x-data-grid";
import ActionsCell from "../components/dashboard/actionButton";
import { Button } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import React, {useState} from "react";
const caseColumnDef: GridColDef[] = [
    { field: 'case_id', headerName: 'ID', width: 90 },
    { field: 'client_name', headerName: 'Client Name', width: 150, sortable: true, filterable: true },
    { field: 'assigned_to', headerName: 'Assigned To', width: 150, sortable: true, filterable: true },
    
    
    // { field: 'territory_name', headerName: 'Territory Name', width: 150, sortable: true, filterable: true },
    
    { field: 'loan_type', headerName: 'Loan Type', width: 150, sortable: true, filterable: true },
    { field: 'dpd_bucket', headerName: 'DPD Bucket', width: 150, sortable: true, filterable: true },
    { field: 'customer_name', headerName: 'Customer Name', width: 150, sortable: true, filterable: true },
    { field: 'customer_number', headerName: 'Customer Contact', width: 150, sortable: true, filterable: true },
    //for the action button
    {
        field: 'actions',
        headerName: 'Actions',
        width: 200,
        renderCell: (params: GridRenderCellParams<any, any, any>) => <ActionsCell params={params} />
    },
    { field: 'collection_amount', headerName: 'Collection Amount', width: 150, sortable: true, filterable: true },
    { field: 'collected_amount', headerName: 'Collected Amount', width: 150, sortable: true, filterable: true },
    // { field: 'settlement_amount', headerName: 'Eligible Settlement Amount', width: 150, sortable: true, filterable: true },
    { field: 'status', headerName: 'Status', width: 150, sortable: true, filterable: true },
    { field: 'interaction', headerName: 'Interaction', width: 150, sortable: true, filterable: true },
    { field: 'sub_interaction', headerName: 'Sub Interaction', width: 150, sortable: true, filterable: true },
    { field: 'ptp_data_or_followup_date', headerName: 'PTP/Follow up Date', width: 150, sortable: true, filterable: true },
    { field: 'ptp_amount', headerName: 'PTP Amount', width: 150, sortable: true, filterable: true },
    { field: 'comment', headerName: 'Comment', width: 150, sortable: true, filterable: true },
    { field: 'interaction_date', headerName: 'Interaction Date', width: 150, sortable: true, filterable: true },
    { field: 'assigned_to', headerName: 'Assigned To', width: 150, sortable: true, filterable: true },
    // { field: 'assigned_at', headerName: 'Assigned At', width: 150, sortable: true, filterable: true },
    { field: 'pin_code', headerName: 'Pin Code', width: 150, sortable: true, filterable: true },
    { field: 'created_date', headerName: 'Created Date', width: 150, sortable: true, filterable: true },
    { field: 'updated_date', headerName: 'Last Update Date', width: 150, sortable: true, filterable: true },
    { field: 'updated_by', headerName: 'Last Update By', width: 150, sortable: true, filterable: true },

];

export default caseColumnDef;