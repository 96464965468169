import { GridColDef } from "@mui/x-data-grid";

const settlementRuleColumnDef: GridColDef[] = [
    { field: 'rule_id', headerName: 'ID', width: 90 },
    { field: 'dpd_bucket', headerName: 'DPD Bucket', width: 150, sortable: true, filterable: true },
    { field: 'settlement_percentage', headerName: 'Settlement Percentage', width: 150, sortable: true, filterable: true },
    { field: 'created_date', headerName: 'Created Date', width: 150, sortable: true, filterable: true },
    { field: 'created_by', headerName: 'Created By', width: 150, sortable: true, filterable: true },
]

export default settlementRuleColumnDef;