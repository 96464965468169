import { Box, Button, IconButton, Modal, TextField, Typography } from "@mui/material";
import modalStyle from "../../styles/modalStyle";
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import apiClient from "../../axiosClient";

interface AddPincodeModalProps {
    openModal: boolean,
    handleCloseModal: () => void,
    selectedTerritory: string|undefined
}

const AddPinCodeModal: React.FC<AddPincodeModalProps> = ({openModal, handleCloseModal, selectedTerritory}) => {
    const [newPincode, setNewPincode] = useState('');

    // Handle form submission to add new pincode
    const handleAddPincode = async () => {
        try {
            const pincodes: string[] = newPincode.split(",");
            const trimmed_pincodes: string[] = [];
            pincodes?.forEach((pincode: string) => {
                if (pincode?.trim().length != 6) {
                    throw Error("Invalid Pincode: " + pincode);
                }
                trimmed_pincodes.push(pincode.trim());
            })
            const response = await apiClient.post(`/api/v1/pin_code`, { pin_code_id: trimmed_pincodes, territory_id: selectedTerritory });
            handleCloseModal(); // Close the modal
            setNewPincode(''); // Clear the form
            alert("Successfully addeded pincode");
        } catch (error: any) {
            if (error.response) {
                alert('Error response data:' + error.response.data.message);
            } else if (error.request) {
                alert('No response received:' + error.request);
            } else {
                alert('Error message:' + error.message);
            }
        }
    };


    return (
        <Modal open={openModal} onClose={handleCloseModal}>
            <Box sx={modalStyle}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography id="modal-title" variant="h6">
                        Add New PinCode
                    </Typography>
                    <IconButton onClick={handleCloseModal}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <TextField
                    label="Add Comma separed pincodes"
                    value={newPincode}
                    onChange={(e) => setNewPincode(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <Box mt={2} textAlign="right">
                    <Button variant="contained" color="primary" onClick={handleAddPincode}>
                        Submit
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default AddPinCodeModal;